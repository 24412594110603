/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PrognoseGruppeTyp = {
    ArgeOebbStopfen: 'ARGE_OEBB_STOPFEN',
    ArgeOebbEdk: 'ARGE_OEBB_EDK',
    OebbNeuschienenschleifen: 'OEBB_NEUSCHIENENSCHLEIFEN',
    Aorv: 'AORV'
} as const;
export type PrognoseGruppeTyp = typeof PrognoseGruppeTyp[keyof typeof PrognoseGruppeTyp];


export function PrognoseGruppeTypFromJSON(json: any): PrognoseGruppeTyp {
    return PrognoseGruppeTypFromJSONTyped(json, false);
}

export function PrognoseGruppeTypFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrognoseGruppeTyp {
    return json as PrognoseGruppeTyp;
}

export function PrognoseGruppeTypToJSON(value?: PrognoseGruppeTyp | null): any {
    return value as any;
}

