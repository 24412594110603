/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SignalWortEreignis } from './SignalWortEreignis';
import {
    SignalWortEreignisFromJSON,
    SignalWortEreignisFromJSONTyped,
    SignalWortEreignisToJSON,
} from './SignalWortEreignis';

/**
 * 
 * @export
 * @interface SignalWort
 */
export interface SignalWort {
    /**
     * 
     * @type {number}
     * @memberof SignalWort
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SignalWort
     */
    wort: string;
    /**
     * Gibt an, ob das ganze Wort übereinstimmen muss, oder in einem anderen Wort enthalten sein kann (Bsp. 'mess' ist in 'Vermessung' enthalten und stimmt nicht mit dem ganzen Wort überein).
     * @type {boolean}
     * @memberof SignalWort
     */
    ganzesWort: boolean;
    /**
     * 
     * @type {string}
     * @memberof SignalWort
     */
    von: string;
    /**
     * 
     * @type {string}
     * @memberof SignalWort
     */
    bis?: string;
    /**
     * 
     * @type {Array<SignalWortEreignis>}
     * @memberof SignalWort
     */
    historie: Array<SignalWortEreignis>;
}

/**
 * Check if a given object implements the SignalWort interface.
 */
export function instanceOfSignalWort(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "wort" in value;
    isInstance = isInstance && "ganzesWort" in value;
    isInstance = isInstance && "von" in value;
    isInstance = isInstance && "historie" in value;

    return isInstance;
}

export function SignalWortFromJSON(json: any): SignalWort {
    return SignalWortFromJSONTyped(json, false);
}

export function SignalWortFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignalWort {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'wort': json['wort'],
        'ganzesWort': json['ganzesWort'],
        'von': json['von'],
        'bis': !exists(json, 'bis') ? undefined : json['bis'],
        'historie': ((json['historie'] as Array<any>).map(SignalWortEreignisFromJSON)),
    };
}

export function SignalWortToJSON(value?: SignalWort | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'wort': value.wort,
        'ganzesWort': value.ganzesWort,
        'von': value.von,
        'bis': value.bis,
        'historie': ((value.historie as Array<any>).map(SignalWortEreignisToJSON)),
    };
}

