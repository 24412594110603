/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BenutzerEreignis
 */
export interface BenutzerEreignis {
    /**
     * 
     * @type {string}
     * @memberof BenutzerEreignis
     */
    benutzerId: string;
    /**
     * 
     * @type {string}
     * @memberof BenutzerEreignis
     */
    zeitpunkt: string;
}

/**
 * Check if a given object implements the BenutzerEreignis interface.
 */
export function instanceOfBenutzerEreignis(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "benutzerId" in value;
    isInstance = isInstance && "zeitpunkt" in value;

    return isInstance;
}

export function BenutzerEreignisFromJSON(json: any): BenutzerEreignis {
    return BenutzerEreignisFromJSONTyped(json, false);
}

export function BenutzerEreignisFromJSONTyped(json: any, ignoreDiscriminator: boolean): BenutzerEreignis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'benutzerId': json['BenutzerId'],
        'zeitpunkt': json['Zeitpunkt'],
    };
}

export function BenutzerEreignisToJSON(value?: BenutzerEreignis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'BenutzerId': value.benutzerId,
        'Zeitpunkt': value.zeitpunkt,
    };
}

