/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  StrategischerUmsatz,
} from '../models/index';
import {
    StrategischerUmsatzFromJSON,
    StrategischerUmsatzToJSON,
} from '../models/index';

export interface StrategischenUmsatzLadenRequest {
    jahr: number;
    prognoseGruppeId: number;
}

export interface UmsatzMaschinengruppeSpeichernRequest {
    jahr: number;
    prognoseGruppeId: number;
    strategischerUmsatz: StrategischerUmsatz;
}

/**
 * StrategieApi - interface
 * 
 * @export
 * @interface StrategieApiInterface
 */
export interface StrategieApiInterface {
    /**
     * 
     * @summary Lädt strategische Umsätze von Maschinengruppen für ein Kalenderjahr und eine Prognosegruppe.
     * @param {number} jahr 
     * @param {number} prognoseGruppeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategieApiInterface
     */
    strategischenUmsatzLadenRaw(requestParameters: StrategischenUmsatzLadenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StrategischerUmsatz>>>;

    /**
     * Lädt strategische Umsätze von Maschinengruppen für ein Kalenderjahr und eine Prognosegruppe.
     */
    strategischenUmsatzLaden(requestParameters: StrategischenUmsatzLadenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StrategischerUmsatz>>;

    /**
     * Für eine neue Maschinengruppe die ID -1 übergeben.
     * @summary Speichert einen strategischen Umsatz einer Maschinengruppe.
     * @param {number} jahr 
     * @param {number} prognoseGruppeId 
     * @param {StrategischerUmsatz} strategischerUmsatz 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StrategieApiInterface
     */
    umsatzMaschinengruppeSpeichernRaw(requestParameters: UmsatzMaschinengruppeSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StrategischerUmsatz>>;

    /**
     * Für eine neue Maschinengruppe die ID -1 übergeben.
     * Speichert einen strategischen Umsatz einer Maschinengruppe.
     */
    umsatzMaschinengruppeSpeichern(requestParameters: UmsatzMaschinengruppeSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StrategischerUmsatz>;

}

/**
 * 
 */
export class StrategieApi extends runtime.BaseAPI implements StrategieApiInterface {

    /**
     * Lädt strategische Umsätze von Maschinengruppen für ein Kalenderjahr und eine Prognosegruppe.
     */
    async strategischenUmsatzLadenRaw(requestParameters: StrategischenUmsatzLadenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StrategischerUmsatz>>> {
        if (requestParameters.jahr === null || requestParameters.jahr === undefined) {
            throw new runtime.RequiredError('jahr','Required parameter requestParameters.jahr was null or undefined when calling strategischenUmsatzLaden.');
        }

        if (requestParameters.prognoseGruppeId === null || requestParameters.prognoseGruppeId === undefined) {
            throw new runtime.RequiredError('prognoseGruppeId','Required parameter requestParameters.prognoseGruppeId was null or undefined when calling strategischenUmsatzLaden.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/strategie/{jahr}/{prognoseGruppeId}`.replace(`{${"jahr"}}`, encodeURIComponent(String(requestParameters.jahr))).replace(`{${"prognoseGruppeId"}}`, encodeURIComponent(String(requestParameters.prognoseGruppeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StrategischerUmsatzFromJSON));
    }

    /**
     * Lädt strategische Umsätze von Maschinengruppen für ein Kalenderjahr und eine Prognosegruppe.
     */
    async strategischenUmsatzLaden(requestParameters: StrategischenUmsatzLadenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StrategischerUmsatz>> {
        const response = await this.strategischenUmsatzLadenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Für eine neue Maschinengruppe die ID -1 übergeben.
     * Speichert einen strategischen Umsatz einer Maschinengruppe.
     */
    async umsatzMaschinengruppeSpeichernRaw(requestParameters: UmsatzMaschinengruppeSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StrategischerUmsatz>> {
        if (requestParameters.jahr === null || requestParameters.jahr === undefined) {
            throw new runtime.RequiredError('jahr','Required parameter requestParameters.jahr was null or undefined when calling umsatzMaschinengruppeSpeichern.');
        }

        if (requestParameters.prognoseGruppeId === null || requestParameters.prognoseGruppeId === undefined) {
            throw new runtime.RequiredError('prognoseGruppeId','Required parameter requestParameters.prognoseGruppeId was null or undefined when calling umsatzMaschinengruppeSpeichern.');
        }

        if (requestParameters.strategischerUmsatz === null || requestParameters.strategischerUmsatz === undefined) {
            throw new runtime.RequiredError('strategischerUmsatz','Required parameter requestParameters.strategischerUmsatz was null or undefined when calling umsatzMaschinengruppeSpeichern.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/strategie/{jahr}/{prognoseGruppeId}`.replace(`{${"jahr"}}`, encodeURIComponent(String(requestParameters.jahr))).replace(`{${"prognoseGruppeId"}}`, encodeURIComponent(String(requestParameters.prognoseGruppeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StrategischerUmsatzToJSON(requestParameters.strategischerUmsatz),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StrategischerUmsatzFromJSON(jsonValue));
    }

    /**
     * Für eine neue Maschinengruppe die ID -1 übergeben.
     * Speichert einen strategischen Umsatz einer Maschinengruppe.
     */
    async umsatzMaschinengruppeSpeichern(requestParameters: UmsatzMaschinengruppeSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StrategischerUmsatz> {
        const response = await this.umsatzMaschinengruppeSpeichernRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
