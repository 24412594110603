import { computed, getCurrentInstance } from "vue";

export enum Locale {
    AT = "de-AT",
    DE = "de-DE",
    EN = "en-US",
}

export const locale = computed(() => {
    if (getCurrentInstance()!.appContext.config.globalProperties.$language.value == 'de') {
        return Locale.AT;
    } else {
        return Locale.EN;
    }
}
);
