import { App, ref } from "vue";
import {
  AccountInfo,
  EventMessage,
  EventMessageUtils,
  EventType,
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalContext } from "@/composition-api/useMsal";

type AccountIdentifiers = Partial<
  Pick<AccountInfo, "homeAccountId" | "localAccountId" | "username">
>;

/**
 * Helper function to determine whether 2 arrays are equal
 * Used to avoid unnecessary state updates
 * @param arrayA
 * @param arrayB
 */
function accountArraysAreEqual(
  arrayA: Array<AccountIdentifiers>,
  arrayB: Array<AccountIdentifiers>,
): boolean {
  if (arrayA.length !== arrayB.length) {
    return false;
  }

  const comparisonArray = [...arrayB];

  return arrayA.every((elementA) => {
    const elementB = comparisonArray.shift();
    if (!elementA || !elementB) {
      return false;
    }

    return (
      elementA.homeAccountId === elementB.homeAccountId &&
      elementA.localAccountId === elementB.localAccountId &&
      elementA.username === elementB.username
    );
  });
}

export const msalPlugin = {
  install: (app: App, msalInstance: PublicClientApplication) => {
    const inProgress = InteractionStatus.Startup as InteractionStatus;
    const accounts = msalInstance.getAllAccounts();
    const listeners = new Map<number, () => void>();

    function addListener(key: number, callback: () => void) {
      listeners.set(key, callback);
    }
    function removeListener(key: number) {
      listeners.delete(key);
    }

    msalInstance.initialize();
    const msalCtx: MsalContext = {
      instance: msalInstance,
      interactionStatus: ref(inProgress),
      accounts: ref(accounts),
      addListener,
      removeListener,
    };

    app.config.globalProperties.$msal = msalCtx;


    msalInstance.addEventCallback((message: EventMessage) => {
      switch (message.eventType) {
        case EventType.ACCOUNT_ADDED:
        case EventType.ACCOUNT_REMOVED:
        case EventType.LOGIN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
        case EventType.HANDLE_REDIRECT_END:
          Array.from(listeners.values()).forEach((c) => c());
          /* eslint-disable-next-line */
        case EventType.LOGIN_FAILURE:
        case EventType.SSO_SILENT_FAILURE:
        case EventType.LOGOUT_END:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_FAILURE:
          {
            const currentAccounts = msalInstance.getAllAccounts();
            if (
              !accountArraysAreEqual(currentAccounts, msalCtx.accounts.value)
            ) {
              msalCtx.accounts.value = currentAccounts;
            }
          }
          break;
      }

      const status = EventMessageUtils.getInteractionStatusFromEvent(
        message,
        msalCtx.interactionStatus.value,
      );
      if (status !== null) {
        msalCtx.interactionStatus.value = status;
      }
    });
  },
};
