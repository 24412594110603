/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UmsatzPrognoseStatus } from './UmsatzPrognoseStatus';
import {
    UmsatzPrognoseStatusFromJSON,
    UmsatzPrognoseStatusFromJSONTyped,
    UmsatzPrognoseStatusToJSON,
} from './UmsatzPrognoseStatus';
import type { UmsatzQuelle } from './UmsatzQuelle';
import {
    UmsatzQuelleFromJSON,
    UmsatzQuelleFromJSONTyped,
    UmsatzQuelleToJSON,
} from './UmsatzQuelle';

/**
 * 
 * @export
 * @interface UmsaetzeKumuliert
 */
export interface UmsaetzeKumuliert {
    /**
     * 
     * @type {number}
     * @memberof UmsaetzeKumuliert
     */
    jahr: number;
    /**
     * 
     * @type {number}
     * @memberof UmsaetzeKumuliert
     */
    monat: number;
    /**
     * 
     * @type {number}
     * @memberof UmsaetzeKumuliert
     */
    firmaId: number;
    /**
     * 
     * @type {string}
     * @memberof UmsaetzeKumuliert
     */
    maschinenGruppe: string;
    /**
     * 
     * @type {UmsatzQuelle}
     * @memberof UmsaetzeKumuliert
     */
    quelle: UmsatzQuelle;
    /**
     * 
     * @type {number}
     * @memberof UmsaetzeKumuliert
     */
    prognoseGruppeId: number;
    /**
     * 
     * @type {UmsatzPrognoseStatus}
     * @memberof UmsaetzeKumuliert
     */
    umsatzPrognoseStatus?: UmsatzPrognoseStatus;
    /**
     * 
     * @type {number}
     * @memberof UmsaetzeKumuliert
     */
    wert: number;
}

/**
 * Check if a given object implements the UmsaetzeKumuliert interface.
 */
export function instanceOfUmsaetzeKumuliert(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "jahr" in value;
    isInstance = isInstance && "monat" in value;
    isInstance = isInstance && "firmaId" in value;
    isInstance = isInstance && "maschinenGruppe" in value;
    isInstance = isInstance && "quelle" in value;
    isInstance = isInstance && "prognoseGruppeId" in value;
    isInstance = isInstance && "wert" in value;

    return isInstance;
}

export function UmsaetzeKumuliertFromJSON(json: any): UmsaetzeKumuliert {
    return UmsaetzeKumuliertFromJSONTyped(json, false);
}

export function UmsaetzeKumuliertFromJSONTyped(json: any, ignoreDiscriminator: boolean): UmsaetzeKumuliert {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jahr': json['jahr'],
        'monat': json['monat'],
        'firmaId': json['firmaId'],
        'maschinenGruppe': json['maschinenGruppe'],
        'quelle': UmsatzQuelleFromJSON(json['quelle']),
        'prognoseGruppeId': json['prognoseGruppeId'],
        'umsatzPrognoseStatus': !exists(json, 'umsatzPrognoseStatus') ? undefined : UmsatzPrognoseStatusFromJSON(json['umsatzPrognoseStatus']),
        'wert': json['wert'],
    };
}

export function UmsaetzeKumuliertToJSON(value?: UmsaetzeKumuliert | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jahr': value.jahr,
        'monat': value.monat,
        'firmaId': value.firmaId,
        'maschinenGruppe': value.maschinenGruppe,
        'quelle': UmsatzQuelleToJSON(value.quelle),
        'prognoseGruppeId': value.prognoseGruppeId,
        'umsatzPrognoseStatus': UmsatzPrognoseStatusToJSON(value.umsatzPrognoseStatus),
        'wert': value.wert,
    };
}

