import { handleHttpError } from "./http";
import { ToastServiceMethods } from "primevue/toastservice";

export const showErrorMessage: (reason: unknown, svcToast: ToastServiceMethods) => void | PromiseLike<void> = (
  reason,
  svcToast,
) => {
  handleHttpError(reason)
    .then((m) => {
      svcToast.add({
        detail: m,
        severity: 'error',
      });
    })
    .catch((m) => console.error(m));
};
