/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BenutzerEreignis } from './BenutzerEreignis';
import {
    BenutzerEreignisFromJSON,
    BenutzerEreignisFromJSONTyped,
    BenutzerEreignisToJSON,
} from './BenutzerEreignis';

/**
 * 
 * @export
 * @interface SignalWortEreignis
 */
export interface SignalWortEreignis {
    /**
     * 
     * @type {string}
     * @memberof SignalWortEreignis
     */
    typ: SignalWortEreignisTypEnum;
    /**
     * Angaben über geänderte Felder (alte Werte und neue Werte).
     * @type {string}
     * @memberof SignalWortEreignis
     */
    aenderung: string;
    /**
     * 
     * @type {BenutzerEreignis}
     * @memberof SignalWortEreignis
     */
    benutzerEreignis: BenutzerEreignis;
    /**
     * 
     * @type {string}
     * @memberof SignalWortEreignis
     */
    grund?: string;
}


/**
 * @export
 */
export const SignalWortEreignisTypEnum = {
    Erstellt: 'ERSTELLT',
    Geaendert: 'GEAENDERT',
    Geloescht: 'GELOESCHT'
} as const;
export type SignalWortEreignisTypEnum = typeof SignalWortEreignisTypEnum[keyof typeof SignalWortEreignisTypEnum];


/**
 * Check if a given object implements the SignalWortEreignis interface.
 */
export function instanceOfSignalWortEreignis(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "typ" in value;
    isInstance = isInstance && "aenderung" in value;
    isInstance = isInstance && "benutzerEreignis" in value;

    return isInstance;
}

export function SignalWortEreignisFromJSON(json: any): SignalWortEreignis {
    return SignalWortEreignisFromJSONTyped(json, false);
}

export function SignalWortEreignisFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignalWortEreignis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typ': json['typ'],
        'aenderung': json['aenderung'],
        'benutzerEreignis': BenutzerEreignisFromJSON(json['benutzerEreignis']),
        'grund': !exists(json, 'grund') ? undefined : json['grund'],
    };
}

export function SignalWortEreignisToJSON(value?: SignalWortEreignis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'typ': value.typ,
        'aenderung': value.aenderung,
        'benutzerEreignis': BenutzerEreignisToJSON(value.benutzerEreignis),
        'grund': value.grund,
    };
}

