import { DateTime } from "luxon";
import { locale } from "./localization";
import { computed } from "vue";

export const ISODateTimeFormat = "YYYY-MM-DDTHH:mm:ssZ";
export const ISODateFormat = "YYYY-MM-DD";

export const localizedDateTimeFormat = computed(() => {
  if (locale.value == "de-AT") {
    return "DD.MM.YYYY HH:mm";
  } else {
    return "MM/DD/YYYY hh:mm A";
  }
});

export const localizedDateTimeWithSecondsFormat = computed(() => {
  if (locale.value == "de-AT") {
    return "DD.MM.YYYY HH:mm:ss";
  } else {
    return "MM/DD/YYYY hh:mm:ss";
  }
});

export const localizedDateFormat = computed(() => {
  if (locale.value == "de-AT") {
    return "DD.MM.YYYY";
  } else {
    return "MM/DD/YYYY";
  }
});

export function formatIsoDateTime(
  isoDate?: string,
  orElse: string = "",
  timeZoneName: TimeZoneName = "short",
): string {
  return isoDate
    ? formatDateTime(DateTime.fromISO(isoDate), timeZoneName)
    : orElse;
}

export function formatDateTime(
  dateTime: DateTime,
  timeZoneName: TimeZoneName = "short",
): string {
  return dateTime.setLocale(locale.value).toLocaleString({
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: timeZone(dateTime, timeZoneName),
  });
}

export function formatIsoDateMonthName(isoDate: string): string {
  return formatDateMonthName(DateTime.fromISO(isoDate));
}

export function formatDateMonthName(date: DateTime): string {
  return date
    .setLocale(locale.value)
    .toLocaleString({ month: 'long' });
}

export function formatIsoDateTimeLong(isoDateTime: string): string {
  return formatDateTimeLong(DateTime.fromISO(isoDateTime));
}

export function formatDateTimeLong(dateTime: DateTime): string {
  return dateTime.setLocale(locale.value).toLocaleString(DateTime.DATETIME_MED);
}

export function formatIsoDateDayMonth(isoDate: string): string {
  return formatDateDayMonth(DateTime.fromISO(isoDate));
}

export function formatDateDayMonth(dateTime: DateTime): string {
  return dateTime
    .setLocale(locale.value)
    .toLocaleString({ day: "2-digit", month: "2-digit" });
}

export function formatIsoDateDayMonthTime(
  isoDateTime?: string,
  orElse: string = "",
  timeZoneName: TimeZoneName = "short",
): string {
  return isoDateTime
    ? formatDateDayMonthTime(
      DateTime.fromISO(isoDateTime),
      orElse,
      timeZoneName,
    )
    : orElse;
}

export function formatDateDayMonthTime(
  dateTime?: DateTime,
  orElse: string = "",
  timeZoneName: TimeZoneName = "short",
): string {
  return dateTime
    ? dateTime.setLocale(locale.value).toLocaleString({
      day: "2-digit",
      month: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZoneName: timeZone(dateTime, timeZoneName),
    })
    : orElse;
}

export function formatIsoDateShort(
  isoDate?: string,
  orElse: string = "",
): string {
  return isoDate ? formatDateShort(DateTime.fromISO(isoDate)) : orElse;
}

export function formatDateShort(date: DateTime): string {
  return date
    .setLocale(locale.value)
    .toLocaleString({ day: "2-digit", month: "2-digit", year: "numeric" });
}

export function formatIsoTime(isoDate: string): string {
  return formatTime(DateTime.fromISO(isoDate));
}

export function formatTime(dateTime: DateTime): string {
  return dateTime
    .setLocale(locale.value)
    .toLocaleString(DateTime.TIME_24_SIMPLE);
}

export function formatIsoTimeSeconds(isoDate: string): string {
  return formatTimeSeconds(DateTime.fromISO(isoDate));
}

export function formatTimeSeconds(dateTime: DateTime): string {
  return dateTime
    .setLocale(locale.value)
    .toLocaleString(DateTime.TIME_24_WITH_SECONDS);
}

export type TimeZoneName =
  | "long"
  | "short"
  | "shortOffset"
  | "longOffset"
  | "shortGeneric"
  | "longGeneric"
  | undefined;

function timeZone(date: DateTime, zoneName: TimeZoneName): TimeZoneName {
  return date.startOf("day").isInDST !== date.endOf("day").isInDST
    ? zoneName
    : undefined;
}
