import { parse } from "@rsql/parser";

import {
  AND,
  AND_VERBOSE,
  EQ,
  ExpressionNode,
  GE,
  GE_VERBOSE,
  getSelector,
  getValue,
  GT,
  GT_VERBOSE,
  IN,
  isComparisonNode,
  isLogicNode,
  LE,
  LE_VERBOSE,
  LT,
  LT_VERBOSE,
  NEQ,
  OR,
  OR_VERBOSE,
} from "@rsql/ast";

export function delegate<T extends object>(first: T, fallback: T): T {
  return new Proxy(first, {
    get(target: T, p: string | symbol): unknown {
      const firstValue = (target as Record<string | symbol, unknown>)[p];
      if (firstValue) {
        return firstValue;
      } else {
        return (fallback as Record<string | symbol, unknown>)[p];
      }
    },
  }) as T;
}

/**
 * Simple RSQL emulation for mocking server results
 * @param filter e.g. "year>=2003"
 */
export function rsqlFilter<T>(
  filter: string | undefined,
): (elem: T) => boolean {
  if (!filter || filter.trim().length == 0) {
    return () => true;
  }
  const expression = parse(filter);
  return (e: T): boolean => compare(expression, e);
}

function compare<T>(n: ExpressionNode, o: T): boolean {
  if (isLogicNode(n)) {
    const resultA = compare(n.left, o);
    switch (n.operator) {
      case AND:
      case AND_VERBOSE:
        if (!resultA) {
          return false;
        } else {
          return compare(n.right, o);
        }
      case OR:
      case OR_VERBOSE:
        if (resultA) {
          return true;
        } else {
          return compare(n.right, o);
        }
      default:
        // TS does not like types after exhaustive switches...
        // throw `Unknown logic operator ${n.operator}`;
        throw `Unknown logic operator`;
    }
  } else if (isComparisonNode(n)) {
    const selector = getSelector(n);
    const expectedValue = getValue(n);
    const actualValue = (o as Record<string, string[] | string>)[selector];

    // TODO types!
    switch (n.operator) {
      case EQ:
        return actualValue == expectedValue; // TODO arrays/objects
      case NEQ:
        return actualValue != expectedValue; // TODO arrays/objects
      case LT:
      case LT_VERBOSE:
        return actualValue < expectedValue;
      case LE:
      case LE_VERBOSE:
        return actualValue <= expectedValue;
      case GT:
      case GT_VERBOSE:
        return actualValue > expectedValue;
      case GE:
      case GE_VERBOSE:
        return actualValue >= expectedValue;
      case IN:
        // TODO arrays/types
        return (
          (actualValue as string[]).indexOf(expectedValue as string) !== -1
        );
      default:
        throw `Unknown operator ${n.operator}`;
    }
  } else {
    throw "Unknown node " + n;
  }
}
