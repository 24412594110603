import { ComponentInternalInstance, getCurrentInstance, Ref, watch } from "vue";
import { scopesBackend } from "@/authConfig";
import { useBackendApiBase } from "./useApiBase";
import { AccessToken } from "@/plugins/apiHelpers";
import { BackendApis } from "@/plugins/backendApiPlugin";
import { useIsAuthenticated } from "./useIsAuthenticated";
import { useGraphApi } from "./useMsGraph";

export function useBackendApi(
  instance: ComponentInternalInstance | null = getCurrentInstance(),
): BackendApis {
  if (!instance) {
    throw new Error("Must only be used inside setup() function of a component");
  }

  const actualWork = async () => {
    return useBackendApiBase({
      vueComponentInstance: instance,
      oauthScopes: scopesBackend,
      tokenValidSetter: (b) => {
        const backendApis =
          instance.appContext.config.globalProperties.$backendApis;
        backendApis.isTokenCurrentlyValid.value = b;
        backendApis.isTokenInitialized.value =
          b || backendApis.isTokenInitialized.value;
      },
      accessToken:
        instance.appContext.config.globalProperties.$backendApiAccessToken,
      lockName: "managementApi",
      accessTokenLock:
        instance.appContext.config.globalProperties.$backendApiAccessTokenLock,
    });
  };

  // Workaround for calling backend before we have the graph stuff.
  // Primarily msal gets confused if two separate oauth flows are started and addressed together (graph and backend).
  // edit: as I am told by the issue tracker and ChatGPT4, MSAL can't be confused, because it knows via "state" what is what (allegedly).

  // But also, backend flow crashes in msal because "no accounts specified" if called before graph, but I don't know what the problem is.
  // edit: hi again, the problem still exists and still don't understand >:( Why do I not need an account for graph, but for the backend?

  // As "quick fix" we always defer the backend creation and force graph flow.
  // edit: defer is broken, since it loses the context ("vue instance") since it asyncs in the "watch"
  //       "Solution" is to expose the vue instance fully to the users of the "base". Not really ideal but it works.
  //       Also fun fact: https://stackoverflow.com/a/72209340 a.k.a not deprecated but should not be used, but can be used by "advanced" plugins.
  //       (Whatever that is supposed to mean...)

  if (useIsAuthenticated(instance).value) {
    actualWork();
  } else {
    useGraphApi();
    const unwatch = watch(useIsAuthenticated(instance), (v) => {
      if (v) {
        actualWork();
        unwatch();
      }
    });
  }

  return instance.appContext.config.globalProperties.$backendApis;
}

declare module "vue" {
  interface ComponentCustomProperties {
    $backendApiAccessToken: Ref<AccessToken | undefined>;
    $backendApis: BackendApis;
    $backendApiAccessTokenLock: Ref<boolean>;
  }
}
