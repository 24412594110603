/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BerechnungsBaum,
  BerechnungsBaumOhneWurzel,
  BerechnungsBaumVeraenderung,
  BerechnungsParameter,
  ParameterTag,
  TagKantenAktualisierenRequest,
  TagSpeichernRequest,
} from '../models/index';
import {
    BerechnungsBaumFromJSON,
    BerechnungsBaumToJSON,
    BerechnungsBaumOhneWurzelFromJSON,
    BerechnungsBaumOhneWurzelToJSON,
    BerechnungsBaumVeraenderungFromJSON,
    BerechnungsBaumVeraenderungToJSON,
    BerechnungsParameterFromJSON,
    BerechnungsParameterToJSON,
    ParameterTagFromJSON,
    ParameterTagToJSON,
    TagKantenAktualisierenRequestFromJSON,
    TagKantenAktualisierenRequestToJSON,
    TagSpeichernRequestFromJSON,
    TagSpeichernRequestToJSON,
} from '../models/index';

export interface BerechnungsBaumRequest {
    id: number;
}

export interface BerechnungsBaumKnotenSpeichernRequest {
    id: number;
    berechnungsBaumVeraenderung: BerechnungsBaumVeraenderung;
}

export interface BerechnungsBaumLoeschenRequest {
    id: number;
}

export interface BerechnungsBaumSpeichernRequest {
    berechnungsBaumOhneWurzel: BerechnungsBaumOhneWurzel;
}

export interface BerechnungsParameterLoeschenRequest {
    id: number;
}

export interface BerechnungsParameterSpeichernRequest {
    berechnungsParameter: BerechnungsParameter;
}

export interface ImportReportManagementReportsRequest {
    requestBody: Array<number>;
}

export interface TagRequest {
    id: number;
}

export interface TagKantenAktualisierenOperationRequest {
    tagKantenAktualisierenRequest: TagKantenAktualisierenRequest;
}

export interface TagLoeschenRequest {
    id: number;
}

export interface TagSpeichernOperationRequest {
    id: number;
    tagSpeichernRequest: TagSpeichernRequest;
}

/**
 * SystemApi - interface
 * 
 * @export
 * @interface SystemApiInterface
 */
export interface SystemApiInterface {
    /**
     * 
     * @summary Gibt alle Berechnungsbäume zurück.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    berechnungsBaeumeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BerechnungsBaum>>>;

    /**
     * Gibt alle Berechnungsbäume zurück.
     */
    berechnungsBaeume(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BerechnungsBaum>>;

    /**
     * 
     * @summary Gibt einen Berechnungsbaum zurück.
     * @param {number} id Gibt die ID des Berechnungsbaumes an.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    berechnungsBaumRaw(requestParameters: BerechnungsBaumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BerechnungsBaum>>;

    /**
     * Gibt einen Berechnungsbaum zurück.
     */
    berechnungsBaum(requestParameters: BerechnungsBaumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BerechnungsBaum>;

    /**
     * Speichert Änderungen, fügt hinzu oder löscht Berechnungsknoten, wobei die Änderungen einen gültigen Berechnungsbaum ergeben müssen. Neue Knoten können durch negative IDs angelegt werden. Diese IDs werden durch automatisch generierte ersetzt und sind im zurückgegebenen Baum enthalten. 
     * @summary Speichert Berechnungsknoten und gibt den Berechnungsbaum zurück.
     * @param {number} id Gibt die ID des Berechnungsbaumes an.
     * @param {BerechnungsBaumVeraenderung} berechnungsBaumVeraenderung 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    berechnungsBaumKnotenSpeichernRaw(requestParameters: BerechnungsBaumKnotenSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BerechnungsBaum>>;

    /**
     * Speichert Änderungen, fügt hinzu oder löscht Berechnungsknoten, wobei die Änderungen einen gültigen Berechnungsbaum ergeben müssen. Neue Knoten können durch negative IDs angelegt werden. Diese IDs werden durch automatisch generierte ersetzt und sind im zurückgegebenen Baum enthalten. 
     * Speichert Berechnungsknoten und gibt den Berechnungsbaum zurück.
     */
    berechnungsBaumKnotenSpeichern(requestParameters: BerechnungsBaumKnotenSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BerechnungsBaum>;

    /**
     * 
     * @summary Löscht einen Berechnungsbaum.
     * @param {number} id Gibt die ID des Berechnungsbaumes an.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    berechnungsBaumLoeschenRaw(requestParameters: BerechnungsBaumLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Löscht einen Berechnungsbaum.
     */
    berechnungsBaumLoeschen(requestParameters: BerechnungsBaumLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Für einen neuen Berechnungsbaum ID -1 angegeben.
     * @summary Speichert einen Berechnungsbaum
     * @param {BerechnungsBaumOhneWurzel} berechnungsBaumOhneWurzel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    berechnungsBaumSpeichernRaw(requestParameters: BerechnungsBaumSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BerechnungsBaum>>;

    /**
     * Für einen neuen Berechnungsbaum ID -1 angegeben.
     * Speichert einen Berechnungsbaum
     */
    berechnungsBaumSpeichern(requestParameters: BerechnungsBaumSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BerechnungsBaum>;

    /**
     * 
     * @summary Löscht einen Berechnungsparameter
     * @param {number} id Gibt die ID des Berechnungsparameters an.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    berechnungsParameterLoeschenRaw(requestParameters: BerechnungsParameterLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Löscht einen Berechnungsparameter
     */
    berechnungsParameterLoeschen(requestParameters: BerechnungsParameterLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Für neue Berechnungsparameter wird die ID -1 angegeben.
     * @summary Speichert einen Berechnungsparameter
     * @param {BerechnungsParameter} berechnungsParameter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    berechnungsParameterSpeichernRaw(requestParameters: BerechnungsParameterSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BerechnungsParameter>>;

    /**
     * Für neue Berechnungsparameter wird die ID -1 angegeben.
     * Speichert einen Berechnungsparameter
     */
    berechnungsParameterSpeichern(requestParameters: BerechnungsParameterSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BerechnungsParameter>;

    /**
     * 
     * @summary Importiert Berichte aus dem Berichtsmanagement
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    importReportManagementReportsRaw(requestParameters: ImportReportManagementReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Importiert Berichte aus dem Berichtsmanagement
     */
    importReportManagementReports(requestParameters: ImportReportManagementReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {number} id Gibt die ID des Parameter-Tags an (-1 beim Erstellen eines neuen Tags).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    tagRaw(requestParameters: TagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParameterTag>>;

    /**
     */
    tag(requestParameters: TagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParameterTag>;

    /**
     * 
     * @summary Entfernt und fügt ParameterTagKanten hinzu.
     * @param {TagKantenAktualisierenRequest} tagKantenAktualisierenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    tagKantenAktualisierenRaw(requestParameters: TagKantenAktualisierenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Entfernt und fügt ParameterTagKanten hinzu.
     */
    tagKantenAktualisieren(requestParameters: TagKantenAktualisierenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Löscht einen Parameter-Tag (sofern keine Kanten mehr vorhanden sind).
     * @param {number} id Gibt die ID des Parameter-Tags an (-1 beim Erstellen eines neuen Tags).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    tagLoeschenRaw(requestParameters: TagLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Löscht einen Parameter-Tag (sofern keine Kanten mehr vorhanden sind).
     */
    tagLoeschen(requestParameters: TagLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Speichert einen Parameter-Tag.
     * @param {number} id Gibt die ID des Parameter-Tags an (-1 beim Erstellen eines neuen Tags).
     * @param {TagSpeichernRequest} tagSpeichernRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    tagSpeichernRaw(requestParameters: TagSpeichernOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParameterTag>>;

    /**
     * Speichert einen Parameter-Tag.
     */
    tagSpeichern(requestParameters: TagSpeichernOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParameterTag>;

}

/**
 * 
 */
export class SystemApi extends runtime.BaseAPI implements SystemApiInterface {

    /**
     * Gibt alle Berechnungsbäume zurück.
     */
    async berechnungsBaeumeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BerechnungsBaum>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/system/berechnungsbaum`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BerechnungsBaumFromJSON));
    }

    /**
     * Gibt alle Berechnungsbäume zurück.
     */
    async berechnungsBaeume(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BerechnungsBaum>> {
        const response = await this.berechnungsBaeumeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gibt einen Berechnungsbaum zurück.
     */
    async berechnungsBaumRaw(requestParameters: BerechnungsBaumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BerechnungsBaum>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling berechnungsBaum.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/system/berechnungsbaum/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BerechnungsBaumFromJSON(jsonValue));
    }

    /**
     * Gibt einen Berechnungsbaum zurück.
     */
    async berechnungsBaum(requestParameters: BerechnungsBaumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BerechnungsBaum> {
        const response = await this.berechnungsBaumRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Speichert Änderungen, fügt hinzu oder löscht Berechnungsknoten, wobei die Änderungen einen gültigen Berechnungsbaum ergeben müssen. Neue Knoten können durch negative IDs angelegt werden. Diese IDs werden durch automatisch generierte ersetzt und sind im zurückgegebenen Baum enthalten. 
     * Speichert Berechnungsknoten und gibt den Berechnungsbaum zurück.
     */
    async berechnungsBaumKnotenSpeichernRaw(requestParameters: BerechnungsBaumKnotenSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BerechnungsBaum>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling berechnungsBaumKnotenSpeichern.');
        }

        if (requestParameters.berechnungsBaumVeraenderung === null || requestParameters.berechnungsBaumVeraenderung === undefined) {
            throw new runtime.RequiredError('berechnungsBaumVeraenderung','Required parameter requestParameters.berechnungsBaumVeraenderung was null or undefined when calling berechnungsBaumKnotenSpeichern.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/system/berechnungsbaum/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BerechnungsBaumVeraenderungToJSON(requestParameters.berechnungsBaumVeraenderung),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BerechnungsBaumFromJSON(jsonValue));
    }

    /**
     * Speichert Änderungen, fügt hinzu oder löscht Berechnungsknoten, wobei die Änderungen einen gültigen Berechnungsbaum ergeben müssen. Neue Knoten können durch negative IDs angelegt werden. Diese IDs werden durch automatisch generierte ersetzt und sind im zurückgegebenen Baum enthalten. 
     * Speichert Berechnungsknoten und gibt den Berechnungsbaum zurück.
     */
    async berechnungsBaumKnotenSpeichern(requestParameters: BerechnungsBaumKnotenSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BerechnungsBaum> {
        const response = await this.berechnungsBaumKnotenSpeichernRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Löscht einen Berechnungsbaum.
     */
    async berechnungsBaumLoeschenRaw(requestParameters: BerechnungsBaumLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling berechnungsBaumLoeschen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/system/berechnungsbaum/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Löscht einen Berechnungsbaum.
     */
    async berechnungsBaumLoeschen(requestParameters: BerechnungsBaumLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.berechnungsBaumLoeschenRaw(requestParameters, initOverrides);
    }

    /**
     * Für einen neuen Berechnungsbaum ID -1 angegeben.
     * Speichert einen Berechnungsbaum
     */
    async berechnungsBaumSpeichernRaw(requestParameters: BerechnungsBaumSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BerechnungsBaum>> {
        if (requestParameters.berechnungsBaumOhneWurzel === null || requestParameters.berechnungsBaumOhneWurzel === undefined) {
            throw new runtime.RequiredError('berechnungsBaumOhneWurzel','Required parameter requestParameters.berechnungsBaumOhneWurzel was null or undefined when calling berechnungsBaumSpeichern.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/system/berechnungsbaum`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BerechnungsBaumOhneWurzelToJSON(requestParameters.berechnungsBaumOhneWurzel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BerechnungsBaumFromJSON(jsonValue));
    }

    /**
     * Für einen neuen Berechnungsbaum ID -1 angegeben.
     * Speichert einen Berechnungsbaum
     */
    async berechnungsBaumSpeichern(requestParameters: BerechnungsBaumSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BerechnungsBaum> {
        const response = await this.berechnungsBaumSpeichernRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Löscht einen Berechnungsparameter
     */
    async berechnungsParameterLoeschenRaw(requestParameters: BerechnungsParameterLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling berechnungsParameterLoeschen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/system/berechnungsparameter/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Löscht einen Berechnungsparameter
     */
    async berechnungsParameterLoeschen(requestParameters: BerechnungsParameterLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.berechnungsParameterLoeschenRaw(requestParameters, initOverrides);
    }

    /**
     * Für neue Berechnungsparameter wird die ID -1 angegeben.
     * Speichert einen Berechnungsparameter
     */
    async berechnungsParameterSpeichernRaw(requestParameters: BerechnungsParameterSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BerechnungsParameter>> {
        if (requestParameters.berechnungsParameter === null || requestParameters.berechnungsParameter === undefined) {
            throw new runtime.RequiredError('berechnungsParameter','Required parameter requestParameters.berechnungsParameter was null or undefined when calling berechnungsParameterSpeichern.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/system/berechnungsparameter`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BerechnungsParameterToJSON(requestParameters.berechnungsParameter),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BerechnungsParameterFromJSON(jsonValue));
    }

    /**
     * Für neue Berechnungsparameter wird die ID -1 angegeben.
     * Speichert einen Berechnungsparameter
     */
    async berechnungsParameterSpeichern(requestParameters: BerechnungsParameterSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BerechnungsParameter> {
        const response = await this.berechnungsParameterSpeichernRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Importiert Berichte aus dem Berichtsmanagement
     */
    async importReportManagementReportsRaw(requestParameters: ImportReportManagementReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling importReportManagementReports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/system/import/reportmanagement/reports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Importiert Berichte aus dem Berichtsmanagement
     */
    async importReportManagementReports(requestParameters: ImportReportManagementReportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.importReportManagementReportsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async tagRaw(requestParameters: TagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParameterTag>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/system/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParameterTagFromJSON(jsonValue));
    }

    /**
     */
    async tag(requestParameters: TagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParameterTag> {
        const response = await this.tagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Entfernt und fügt ParameterTagKanten hinzu.
     */
    async tagKantenAktualisierenRaw(requestParameters: TagKantenAktualisierenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tagKantenAktualisierenRequest === null || requestParameters.tagKantenAktualisierenRequest === undefined) {
            throw new runtime.RequiredError('tagKantenAktualisierenRequest','Required parameter requestParameters.tagKantenAktualisierenRequest was null or undefined when calling tagKantenAktualisieren.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/system/tags/kanten`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagKantenAktualisierenRequestToJSON(requestParameters.tagKantenAktualisierenRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Entfernt und fügt ParameterTagKanten hinzu.
     */
    async tagKantenAktualisieren(requestParameters: TagKantenAktualisierenOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.tagKantenAktualisierenRaw(requestParameters, initOverrides);
    }

    /**
     * Löscht einen Parameter-Tag (sofern keine Kanten mehr vorhanden sind).
     */
    async tagLoeschenRaw(requestParameters: TagLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tagLoeschen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/system/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Löscht einen Parameter-Tag (sofern keine Kanten mehr vorhanden sind).
     */
    async tagLoeschen(requestParameters: TagLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.tagLoeschenRaw(requestParameters, initOverrides);
    }

    /**
     * Speichert einen Parameter-Tag.
     */
    async tagSpeichernRaw(requestParameters: TagSpeichernOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParameterTag>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tagSpeichern.');
        }

        if (requestParameters.tagSpeichernRequest === null || requestParameters.tagSpeichernRequest === undefined) {
            throw new runtime.RequiredError('tagSpeichernRequest','Required parameter requestParameters.tagSpeichernRequest was null or undefined when calling tagSpeichern.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/system/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TagSpeichernRequestToJSON(requestParameters.tagSpeichernRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParameterTagFromJSON(jsonValue));
    }

    /**
     * Speichert einen Parameter-Tag.
     */
    async tagSpeichern(requestParameters: TagSpeichernOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParameterTag> {
        const response = await this.tagSpeichernRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
