import { ToastServiceMethods } from "primevue/toastservice";
import { showErrorMessage } from "./errorHandler";

export function clone<T>(v: T): T {
  return JSON.parse(JSON.stringify(v));
}

/**
 * Gets all-string values of a string-enum. Type-safety is not possible so only pass a string-enum in!
 * <pre>
 * enum MyEnum {
 *   KEY = "VALUE",
 * }
 * </pre>
 */
export function getStringEnumValues(_enum: { [p: string]: string }): string[] {
  return Object.values(_enum).filter((item) => {
    return isNaN(Number(item)); // I don't remember, but I guess it filters the number-indexes out to retain only the string enum values
  });
}

/**
 * Gets a enum key by its string value. Returns undefined if value does not exist.
 * @param value The string value to find the key for.
 * @param _enum The enumeration type.
 */
export const getEnumKeyByStringValue = <T extends Record<string, string>>(
  value: string,
  _enum: T,
): T[keyof T] | undefined => {
  const key = Object.keys(_enum).find((k) => _enum[k] === value);
  return key ? _enum[key as keyof T] : undefined;
};

export const toBase64DataUri = (blob: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export async function toBase64DataUriWithErrorMessage(
  file: File,
  toast: ToastServiceMethods,
): Promise<string | undefined> {
  try {
    const uri = await toBase64DataUri(file);
    return uri;
  } catch (e) {
    showErrorMessage(e, toast);
  }
  return undefined;
}

/**
 * Intersects an Array with others.
 * @param arr1 First set of intersection
 * @param others Other sets to intersect with first set
 * @returns Intersection of the given sets
 */
export function intersect<T>(
  arr1: Array<T>,
  ...others: Array<Array<T>>
): Array<T> {
  if (!others.length) {
    return arr1;
  }
  return Array.from(arr1.filter((x) => others.every((y) => y.includes(x))));
}

export function arraysEqual<T>(a: T[], b: T[], ignoreOrder: boolean = true): boolean {
  if (a.length != b.length) {
    return false;
  }

  if (ignoreOrder) {
    a = a.toSorted();
    b = b.toSorted();
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}
