/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignalWortSpeichernRequest
 */
export interface SignalWortSpeichernRequest {
    /**
     * 
     * @type {number}
     * @memberof SignalWortSpeichernRequest
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SignalWortSpeichernRequest
     */
    wort: string;
    /**
     * Gibt an, ob das ganze Wort übereinstimmen muss, oder in einem anderen Wort enthalten sein kann (Bsp. 'mess' ist in 'Vermessung' enthalten und stimmt nicht mit dem ganzen Wort überein).
     * @type {boolean}
     * @memberof SignalWortSpeichernRequest
     */
    ganzesWort: boolean;
    /**
     * 
     * @type {string}
     * @memberof SignalWortSpeichernRequest
     */
    von: string;
    /**
     * 
     * @type {string}
     * @memberof SignalWortSpeichernRequest
     */
    bis?: string;
    /**
     * Gibt den Grund einer Änderung an.
     * @type {string}
     * @memberof SignalWortSpeichernRequest
     */
    grund?: string;
}

/**
 * Check if a given object implements the SignalWortSpeichernRequest interface.
 */
export function instanceOfSignalWortSpeichernRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "wort" in value;
    isInstance = isInstance && "ganzesWort" in value;
    isInstance = isInstance && "von" in value;

    return isInstance;
}

export function SignalWortSpeichernRequestFromJSON(json: any): SignalWortSpeichernRequest {
    return SignalWortSpeichernRequestFromJSONTyped(json, false);
}

export function SignalWortSpeichernRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignalWortSpeichernRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'wort': json['wort'],
        'ganzesWort': json['ganzesWort'],
        'von': json['von'],
        'bis': !exists(json, 'bis') ? undefined : json['bis'],
        'grund': !exists(json, 'grund') ? undefined : json['grund'],
    };
}

export function SignalWortSpeichernRequestToJSON(value?: SignalWortSpeichernRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'wort': value.wort,
        'ganzesWort': value.ganzesWort,
        'von': value.von,
        'bis': value.bis,
        'grund': value.grund,
    };
}

