/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  KalkulatorischerAnsatz,
  SignalWort,
  SignalWortSpeichernRequest,
  UmsaetzeAusschnitt,
  Umsatz,
  UmsatzSpeichernRequest,
} from '../models/index';
import {
    KalkulatorischerAnsatzFromJSON,
    KalkulatorischerAnsatzToJSON,
    SignalWortFromJSON,
    SignalWortToJSON,
    SignalWortSpeichernRequestFromJSON,
    SignalWortSpeichernRequestToJSON,
    UmsaetzeAusschnittFromJSON,
    UmsaetzeAusschnittToJSON,
    UmsatzFromJSON,
    UmsatzToJSON,
    UmsatzSpeichernRequestFromJSON,
    UmsatzSpeichernRequestToJSON,
} from '../models/index';

export interface EinsatzUmsatzRequest {
    railiumId: number;
}

export interface KalkulatorischeAnsaetzeRequest {
    filter: string;
}

export interface KalkulatorischeAnsaetzeSpeichernRequest {
    kalkulatorischerAnsatz: Array<KalkulatorischerAnsatz>;
}

export interface KalkulatorischenAnsatzSpeichernRequest {
    kalkulatorischerAnsatz: KalkulatorischerAnsatz;
}

export interface SignalWoerterRequest {
    filter: string;
}

export interface SignalWortRequest {
    id: number;
}

export interface SignalWortSpeichernOperationRequest {
    signalWortSpeichernRequest: SignalWortSpeichernRequest;
}

export interface UmsaetzeAusschnittRequest {
    seite: number;
    anzahl: number;
    filter: string;
}

export interface UmsatzRequest {
    id: number;
}

export interface UmsatzHistorieRequest {
    id: number;
}

export interface UmsatzLoeschenRequest {
    id: number;
}

export interface UmsatzSpeichernOperationRequest {
    umsatzSpeichernRequest: UmsatzSpeichernRequest;
}

/**
 * PrognoseApi - interface
 * 
 * @export
 * @interface PrognoseApiInterface
 */
export interface PrognoseApiInterface {
    /**
     * 
     * @summary Gibt den Umsatz für die übergebene Railium-ID zurück.
     * @param {number} railiumId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrognoseApiInterface
     */
    einsatzUmsatzRaw(requestParameters: EinsatzUmsatzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Umsatz>>;

    /**
     * Gibt den Umsatz für die übergebene Railium-ID zurück.
     */
    einsatzUmsatz(requestParameters: EinsatzUmsatzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Umsatz>;

    /**
     * 
     * @summary Gibt eine Liste von kalkulatorischen Ansätzen für den angegebenen RSQL-Filter zurück.
     * @param {string} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrognoseApiInterface
     */
    kalkulatorischeAnsaetzeRaw(requestParameters: KalkulatorischeAnsaetzeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KalkulatorischerAnsatz>>>;

    /**
     * Gibt eine Liste von kalkulatorischen Ansätzen für den angegebenen RSQL-Filter zurück.
     */
    kalkulatorischeAnsaetze(requestParameters: KalkulatorischeAnsaetzeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KalkulatorischerAnsatz>>;

    /**
     * Für neue kalkulatorische Ansätze ID -1 angegeben.
     * @summary Speichert kalkulatorische Ansätze.
     * @param {Array<KalkulatorischerAnsatz>} kalkulatorischerAnsatz 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrognoseApiInterface
     */
    kalkulatorischeAnsaetzeSpeichernRaw(requestParameters: KalkulatorischeAnsaetzeSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KalkulatorischerAnsatz>>>;

    /**
     * Für neue kalkulatorische Ansätze ID -1 angegeben.
     * Speichert kalkulatorische Ansätze.
     */
    kalkulatorischeAnsaetzeSpeichern(requestParameters: KalkulatorischeAnsaetzeSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KalkulatorischerAnsatz>>;

    /**
     * Für einen neuen kalkulatorischen Ansatz ID -1 angegeben.
     * @summary Speichert einen kalkulatorischen Ansatz.
     * @param {KalkulatorischerAnsatz} kalkulatorischerAnsatz 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrognoseApiInterface
     */
    kalkulatorischenAnsatzSpeichernRaw(requestParameters: KalkulatorischenAnsatzSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KalkulatorischerAnsatz>>;

    /**
     * Für einen neuen kalkulatorischen Ansatz ID -1 angegeben.
     * Speichert einen kalkulatorischen Ansatz.
     */
    kalkulatorischenAnsatzSpeichern(requestParameters: KalkulatorischenAnsatzSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KalkulatorischerAnsatz>;

    /**
     * 
     * @summary Liste von Signalwörtern
     * @param {string} filter Gibt den RSQL-Filter für die gewünschten Signalwörter an.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrognoseApiInterface
     */
    signalWoerterRaw(requestParameters: SignalWoerterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SignalWort>>>;

    /**
     * Liste von Signalwörtern
     */
    signalWoerter(requestParameters: SignalWoerterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SignalWort>>;

    /**
     * 
     * @summary Signalwort
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrognoseApiInterface
     */
    signalWortRaw(requestParameters: SignalWortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignalWort>>;

    /**
     * Signalwort
     */
    signalWort(requestParameters: SignalWortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignalWort>;

    /**
     * Für ein neues Signalwort ID -1 angegeben.
     * @summary Speichern
     * @param {SignalWortSpeichernRequest} signalWortSpeichernRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrognoseApiInterface
     */
    signalWortSpeichernRaw(requestParameters: SignalWortSpeichernOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignalWort>>;

    /**
     * Für ein neues Signalwort ID -1 angegeben.
     * Speichern
     */
    signalWortSpeichern(requestParameters: SignalWortSpeichernOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignalWort>;

    /**
     * 
     * @summary Gibt einen Auschnitt von Umsätzen für den übergebenen RSQL-Filter zurück.
     * @param {number} seite Seitennummer bei mehreren Seiten.
     * @param {number} anzahl Gibt die Anzahl der Elemente pro Seite an.
     * @param {string} filter Gibt den RSQL-Filter für die gewünschten Elemente an.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrognoseApiInterface
     */
    umsaetzeAusschnittRaw(requestParameters: UmsaetzeAusschnittRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UmsaetzeAusschnitt>>;

    /**
     * Gibt einen Auschnitt von Umsätzen für den übergebenen RSQL-Filter zurück.
     */
    umsaetzeAusschnitt(requestParameters: UmsaetzeAusschnittRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UmsaetzeAusschnitt>;

    /**
     * 
     * @summary Gibt den Umsatz für die übergebene ID zurück.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrognoseApiInterface
     */
    umsatzRaw(requestParameters: UmsatzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Umsatz>>;

    /**
     * Gibt den Umsatz für die übergebene ID zurück.
     */
    umsatz(requestParameters: UmsatzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Umsatz>;

    /**
     * 
     * @summary Gibt alle historischen Versionen des Umsatzes für die übergebene ID zurück.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrognoseApiInterface
     */
    umsatzHistorieRaw(requestParameters: UmsatzHistorieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Umsatz>>>;

    /**
     * Gibt alle historischen Versionen des Umsatzes für die übergebene ID zurück.
     */
    umsatzHistorie(requestParameters: UmsatzHistorieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Umsatz>>;

    /**
     * 
     * @summary Löscht den Umsatz mit der übergebenen ID.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrognoseApiInterface
     */
    umsatzLoeschenRaw(requestParameters: UmsatzLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Löscht den Umsatz mit der übergebenen ID.
     */
    umsatzLoeschen(requestParameters: UmsatzLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Speichert einen Umsatz.
     * @param {UmsatzSpeichernRequest} umsatzSpeichernRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrognoseApiInterface
     */
    umsatzSpeichernRaw(requestParameters: UmsatzSpeichernOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Umsatz>>;

    /**
     * Speichert einen Umsatz.
     */
    umsatzSpeichern(requestParameters: UmsatzSpeichernOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Umsatz>;

}

/**
 * 
 */
export class PrognoseApi extends runtime.BaseAPI implements PrognoseApiInterface {

    /**
     * Gibt den Umsatz für die übergebene Railium-ID zurück.
     */
    async einsatzUmsatzRaw(requestParameters: EinsatzUmsatzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Umsatz>> {
        if (requestParameters.railiumId === null || requestParameters.railiumId === undefined) {
            throw new runtime.RequiredError('railiumId','Required parameter requestParameters.railiumId was null or undefined when calling einsatzUmsatz.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/prognose/umsatz/einsatz/{railiumId}`.replace(`{${"railiumId"}}`, encodeURIComponent(String(requestParameters.railiumId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UmsatzFromJSON(jsonValue));
    }

    /**
     * Gibt den Umsatz für die übergebene Railium-ID zurück.
     */
    async einsatzUmsatz(requestParameters: EinsatzUmsatzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Umsatz> {
        const response = await this.einsatzUmsatzRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt eine Liste von kalkulatorischen Ansätzen für den angegebenen RSQL-Filter zurück.
     */
    async kalkulatorischeAnsaetzeRaw(requestParameters: KalkulatorischeAnsaetzeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KalkulatorischerAnsatz>>> {
        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling kalkulatorischeAnsaetze.');
        }

        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/prognose/kalkulatorischeansaetze`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KalkulatorischerAnsatzFromJSON));
    }

    /**
     * Gibt eine Liste von kalkulatorischen Ansätzen für den angegebenen RSQL-Filter zurück.
     */
    async kalkulatorischeAnsaetze(requestParameters: KalkulatorischeAnsaetzeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KalkulatorischerAnsatz>> {
        const response = await this.kalkulatorischeAnsaetzeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Für neue kalkulatorische Ansätze ID -1 angegeben.
     * Speichert kalkulatorische Ansätze.
     */
    async kalkulatorischeAnsaetzeSpeichernRaw(requestParameters: KalkulatorischeAnsaetzeSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KalkulatorischerAnsatz>>> {
        if (requestParameters.kalkulatorischerAnsatz === null || requestParameters.kalkulatorischerAnsatz === undefined) {
            throw new runtime.RequiredError('kalkulatorischerAnsatz','Required parameter requestParameters.kalkulatorischerAnsatz was null or undefined when calling kalkulatorischeAnsaetzeSpeichern.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/prognose/kalkulatorischeansaetze`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.kalkulatorischerAnsatz.map(KalkulatorischerAnsatzToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KalkulatorischerAnsatzFromJSON));
    }

    /**
     * Für neue kalkulatorische Ansätze ID -1 angegeben.
     * Speichert kalkulatorische Ansätze.
     */
    async kalkulatorischeAnsaetzeSpeichern(requestParameters: KalkulatorischeAnsaetzeSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KalkulatorischerAnsatz>> {
        const response = await this.kalkulatorischeAnsaetzeSpeichernRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Für einen neuen kalkulatorischen Ansatz ID -1 angegeben.
     * Speichert einen kalkulatorischen Ansatz.
     */
    async kalkulatorischenAnsatzSpeichernRaw(requestParameters: KalkulatorischenAnsatzSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KalkulatorischerAnsatz>> {
        if (requestParameters.kalkulatorischerAnsatz === null || requestParameters.kalkulatorischerAnsatz === undefined) {
            throw new runtime.RequiredError('kalkulatorischerAnsatz','Required parameter requestParameters.kalkulatorischerAnsatz was null or undefined when calling kalkulatorischenAnsatzSpeichern.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/prognose/kalkulatorischeransatz`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KalkulatorischerAnsatzToJSON(requestParameters.kalkulatorischerAnsatz),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KalkulatorischerAnsatzFromJSON(jsonValue));
    }

    /**
     * Für einen neuen kalkulatorischen Ansatz ID -1 angegeben.
     * Speichert einen kalkulatorischen Ansatz.
     */
    async kalkulatorischenAnsatzSpeichern(requestParameters: KalkulatorischenAnsatzSpeichernRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KalkulatorischerAnsatz> {
        const response = await this.kalkulatorischenAnsatzSpeichernRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste von Signalwörtern
     */
    async signalWoerterRaw(requestParameters: SignalWoerterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SignalWort>>> {
        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling signalWoerter.');
        }

        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/prognose/signalwoerter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SignalWortFromJSON));
    }

    /**
     * Liste von Signalwörtern
     */
    async signalWoerter(requestParameters: SignalWoerterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SignalWort>> {
        const response = await this.signalWoerterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Signalwort
     */
    async signalWortRaw(requestParameters: SignalWortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignalWort>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling signalWort.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/prognose/signalwoerter/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignalWortFromJSON(jsonValue));
    }

    /**
     * Signalwort
     */
    async signalWort(requestParameters: SignalWortRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignalWort> {
        const response = await this.signalWortRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Für ein neues Signalwort ID -1 angegeben.
     * Speichern
     */
    async signalWortSpeichernRaw(requestParameters: SignalWortSpeichernOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignalWort>> {
        if (requestParameters.signalWortSpeichernRequest === null || requestParameters.signalWortSpeichernRequest === undefined) {
            throw new runtime.RequiredError('signalWortSpeichernRequest','Required parameter requestParameters.signalWortSpeichernRequest was null or undefined when calling signalWortSpeichern.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/prognose/signalwoerter`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SignalWortSpeichernRequestToJSON(requestParameters.signalWortSpeichernRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignalWortFromJSON(jsonValue));
    }

    /**
     * Für ein neues Signalwort ID -1 angegeben.
     * Speichern
     */
    async signalWortSpeichern(requestParameters: SignalWortSpeichernOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignalWort> {
        const response = await this.signalWortSpeichernRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt einen Auschnitt von Umsätzen für den übergebenen RSQL-Filter zurück.
     */
    async umsaetzeAusschnittRaw(requestParameters: UmsaetzeAusschnittRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UmsaetzeAusschnitt>> {
        if (requestParameters.seite === null || requestParameters.seite === undefined) {
            throw new runtime.RequiredError('seite','Required parameter requestParameters.seite was null or undefined when calling umsaetzeAusschnitt.');
        }

        if (requestParameters.anzahl === null || requestParameters.anzahl === undefined) {
            throw new runtime.RequiredError('anzahl','Required parameter requestParameters.anzahl was null or undefined when calling umsaetzeAusschnitt.');
        }

        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling umsaetzeAusschnitt.');
        }

        const queryParameters: any = {};

        if (requestParameters.seite !== undefined) {
            queryParameters['seite'] = requestParameters.seite;
        }

        if (requestParameters.anzahl !== undefined) {
            queryParameters['anzahl'] = requestParameters.anzahl;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/prognose/umsaetze/ausschnitt`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UmsaetzeAusschnittFromJSON(jsonValue));
    }

    /**
     * Gibt einen Auschnitt von Umsätzen für den übergebenen RSQL-Filter zurück.
     */
    async umsaetzeAusschnitt(requestParameters: UmsaetzeAusschnittRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UmsaetzeAusschnitt> {
        const response = await this.umsaetzeAusschnittRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt den Umsatz für die übergebene ID zurück.
     */
    async umsatzRaw(requestParameters: UmsatzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Umsatz>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling umsatz.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/prognose/umsatz/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UmsatzFromJSON(jsonValue));
    }

    /**
     * Gibt den Umsatz für die übergebene ID zurück.
     */
    async umsatz(requestParameters: UmsatzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Umsatz> {
        const response = await this.umsatzRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt alle historischen Versionen des Umsatzes für die übergebene ID zurück.
     */
    async umsatzHistorieRaw(requestParameters: UmsatzHistorieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Umsatz>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling umsatzHistorie.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/prognose/umsatz/{id}/historie`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UmsatzFromJSON));
    }

    /**
     * Gibt alle historischen Versionen des Umsatzes für die übergebene ID zurück.
     */
    async umsatzHistorie(requestParameters: UmsatzHistorieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Umsatz>> {
        const response = await this.umsatzHistorieRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Löscht den Umsatz mit der übergebenen ID.
     */
    async umsatzLoeschenRaw(requestParameters: UmsatzLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling umsatzLoeschen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/prognose/umsatz/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Löscht den Umsatz mit der übergebenen ID.
     */
    async umsatzLoeschen(requestParameters: UmsatzLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.umsatzLoeschenRaw(requestParameters, initOverrides);
    }

    /**
     * Speichert einen Umsatz.
     */
    async umsatzSpeichernRaw(requestParameters: UmsatzSpeichernOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Umsatz>> {
        if (requestParameters.umsatzSpeichernRequest === null || requestParameters.umsatzSpeichernRequest === undefined) {
            throw new runtime.RequiredError('umsatzSpeichernRequest','Required parameter requestParameters.umsatzSpeichernRequest was null or undefined when calling umsatzSpeichern.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/prognose/umsatz`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UmsatzSpeichernRequestToJSON(requestParameters.umsatzSpeichernRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UmsatzFromJSON(jsonValue));
    }

    /**
     * Speichert einen Umsatz.
     */
    async umsatzSpeichern(requestParameters: UmsatzSpeichernOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Umsatz> {
        const response = await this.umsatzSpeichernRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
