<template>
  <nav-bar />
  <div class="page-body">
    <Toast position="top-center" style="width: fit-content; min-width: 20%" />
    <ConfirmDialog />
    <router-view />
  </div>
  <sticky-footer
    :show-warning="showWarning"
    :version="'Version ' + appVersion + ' vom ' + appTimestamp"
    :warning="warningMsg"
  />
</template>

<script lang="ts" setup>
  import NavBar from "./components/NavBar.vue";
  import { RouterView } from "vue-router";
  import {
    showProductionSystemWarning,
    showTestSystemWarning,
  } from "./utils/devutils";
  import { formatIsoDateTime } from "./utils/dateFormats";
  import { computed, getCurrentInstance, watch } from "vue";
  import Toast from "primevue/toast";
  import ConfirmDialog from "primevue/confirmdialog";
  import { usePrimeVue } from "primevue/config";
  import StickyFooter from "./components/StickyFooter.vue";
  import locale_de from "./primelocale_de-at.json?raw";
  import locale_en from "./primelocale_en.json?raw";

  const primevue = usePrimeVue();
  watch(
    getCurrentInstance()!.appContext.config.globalProperties.$language,
    (lng) => {
      if (lng === "de") {
        primevue.config.locale = JSON.parse(locale_de);
        console.log("changed locale to " + lng);
      } else {
        primevue.config.locale = JSON.parse(locale_en);
        console.log("changed locale to en");
      }
    },
    { immediate: true },
  );

  const warningMsg = computed(() => {
    if (showTestSystemWarning) {
      return "Du arbeitest am Testsystem!";
    } else if (showProductionSystemWarning) {
      return "Du arbeitest am Produktivsystem!";
    }
    return undefined;
  });

  const hostnameIncludesPrd = window.location.hostname.includes(".prd.");
  console.log("running in production environment: " + hostnameIncludesPrd);

  const appVersion = __APP_VERSION__;
  const appTimestamp = formatIsoDateTime(__APP_TIMESTAMP__);

  const showWarning = computed(
    () => showProductionSystemWarning.value || showTestSystemWarning.value,
  );
</script>

<style lang="scss">
  #app {
    color: var(--app-text-color);
    height: 100%;
    width: 100%;
    background-image: url("/images/rsrg_lines.svg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 11em;
  }
  html {
    font-family: "Corporate S", Arial, sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background-color: var(--app-bg-color);
  }

  body {
    margin: 0;
    padding: 0;
  }

  .page-body {
    overflow-x: auto;
    margin: 0.5em;
    height: 100%;
  }
</style>
