/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UmsaetzeKumuliert,
} from '../models/index';
import {
    UmsaetzeKumuliertFromJSON,
    UmsaetzeKumuliertToJSON,
} from '../models/index';

export interface StatistikKumuliertRequest {
    filter?: string;
}

/**
 * StatistikApi - interface
 * 
 * @export
 * @interface StatistikApiInterface
 */
export interface StatistikApiInterface {
    /**
     * 
     * @summary Gibt kumulierte Soll- und Ist-Werte für einen angegebenen RSQL-Filter zurück.
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatistikApiInterface
     */
    statistikKumuliertRaw(requestParameters: StatistikKumuliertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UmsaetzeKumuliert>>>;

    /**
     * Gibt kumulierte Soll- und Ist-Werte für einen angegebenen RSQL-Filter zurück.
     */
    statistikKumuliert(requestParameters: StatistikKumuliertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UmsaetzeKumuliert>>;

}

/**
 * 
 */
export class StatistikApi extends runtime.BaseAPI implements StatistikApiInterface {

    /**
     * Gibt kumulierte Soll- und Ist-Werte für einen angegebenen RSQL-Filter zurück.
     */
    async statistikKumuliertRaw(requestParameters: StatistikKumuliertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UmsaetzeKumuliert>>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/statistik/kumuliert`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UmsaetzeKumuliertFromJSON));
    }

    /**
     * Gibt kumulierte Soll- und Ist-Werte für einen angegebenen RSQL-Filter zurück.
     */
    async statistikKumuliert(requestParameters: StatistikKumuliertRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UmsaetzeKumuliert>> {
        const response = await this.statistikKumuliertRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
