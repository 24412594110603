/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AllgemeinApiInterface,
  PrognoseApiInterface,
  PrognoseGruppeTyp,
  RailiumApiInterface,
  Umsatz,
  UmsatzQuelle,
  BerechnungsParameterWertQuelle,
  ParameterTag,
  SystemApiInterface,
  UmsatzPrognoseStatus,
  StrategieApiInterface,
} from "@apis/backend";

function defer<T>(f: () => T | Promise<T>): Promise<T> { // eslint-disable-line

  return new Promise((resolve) => {
    window.setTimeout(() => {
      resolve(Promise.resolve(f()));
    }, 300);
  });
}

enum ParameterId {
  Stopfen_Mindestverrechnung_Euro_Stueck = 1,
  Stopfen_Mindestverrechnung_Anzahl = 2,
  Stopfen_Weiche_Neulage_Anzahl = 3,
  Stopfen_Weiche_Erhaltung_Anzahl = 4,
  Stopfen_Weiche_Neulage_Euro_Stueck = 5,
  Stopfen_Weiche_Erhaltung_Euro_Stueck = 6,
  Stopfen_Gleis_Neulage_Meter = 7,
  Stopfen_Gleis_Erhaltung_Meter = 8,
  Stopfen_Gleis_Neulage_Meter_Euro = 9,
  Stopfen_Gleis_Erhaltung_Euro_Meter = 10,
  Stopfen_Gleis_Neulage_Befahrbar_Meter = 11,
  Stopfen_Gleis_Neulage_Befahrbar_Meter_Euro = 12,
}

enum UnitId {
  Euro = 1,
  Meter = 2,
  Euro_pro_Stueck = 3,
  Euro_pro_Meter = 4,
  Stueck = 5,
}

enum MachineGroup {
  MDZ_B1 = "GW-MDZ B1",
  MDZ_B4 = "GW-MDZ B4",
  MDZ_B5 = "GW-MDZ B5",
  PLM_B1 = "PLM B1",
  PLM_S1 = "PLM S1",
  SSM_1 = "SSM 1",
}

export enum TagName {
  Stopfen = 1,
  Kran = 2,
  Schleifen = 3,
  AORV = 4,
  Gleis = 5,
  Weiche = 6,
  Kranhelfer = 7,
  Neulage = 8,
  Erhaltung = 9,
  GroßeSchicht = 10,
  KleineSchicht = 11,
  Meter = 12,
  Stuek = 13,
  EuroProMeter = 14,
  EuroProStueck = 15,
  Euro = 23,
  Mindestverrechnung = 22,
  Befahrbar = 20,
}

type TagMap = {
  [key in TagName]: ParameterTag;
};

const tags: TagMap = { // eslint-disable-line
  [TagName.Stopfen]: {
    id: TagName.Stopfen,
    name: "Stopfen",
    typId: 1,
    nachbarIds: [
      TagName.Gleis,
      TagName.Weiche,
      TagName.Neulage,
      TagName.Erhaltung,
      TagName.Meter,
      TagName.Stuek,
      TagName.EuroProStueck,
      TagName.EuroProMeter,
      TagName.Mindestverrechnung,
      TagName.Befahrbar,
    ],
    optional: false,
  },
  [TagName.Schleifen]: {
    id: TagName.Schleifen,
    name: "Schleifen",
    typId: 1,
    nachbarIds: [
      TagName.Gleis,
      TagName.Weiche,
      TagName.Neulage,
      TagName.Meter,
      TagName.Stuek,
      TagName.EuroProMeter,
      TagName.EuroProStueck,
    ],
    optional: false,
  },
  [TagName.Gleis]: {
    id: TagName.Gleis,
    name: "Gleis",
    typId: 2,
    nachbarIds: [
      TagName.Stopfen,
      TagName.Schleifen,
      TagName.Neulage,
      TagName.Erhaltung,
      TagName.Meter,
      TagName.EuroProMeter,
      TagName.Befahrbar,
    ],
    optional: false,
  },
  [TagName.Weiche]: {
    id: TagName.Weiche,
    name: "Weiche",
    typId: 2,
    nachbarIds: [
      TagName.Stopfen,
      TagName.Schleifen,
      TagName.Neulage,
      TagName.Erhaltung,
      TagName.Stuek,
      TagName.EuroProStueck,
      TagName.Befahrbar,
    ],
    optional: false,
  },
  [TagName.Neulage]: {
    id: TagName.Neulage,
    name: "Neulage",
    typId: 3,
    nachbarIds: [
      TagName.Stopfen,
      TagName.Schleifen,
      TagName.Gleis,
      TagName.Weiche,
      TagName.Meter,
      TagName.Stuek,
      TagName.EuroProMeter,
      TagName.EuroProStueck,
      TagName.Befahrbar,
    ],
    optional: false,
  },
  [TagName.Erhaltung]: {
    id: TagName.Erhaltung,
    name: "Erhaltung",
    typId: 3,
    nachbarIds: [
      TagName.Stopfen,
      TagName.Gleis,
      TagName.Weiche,
      TagName.Meter,
      TagName.Stuek,
    ],
    optional: false,
  },
  [TagName.Befahrbar]: {
    id: TagName.Befahrbar,
    name: "Befahrbar",
    typId: 5,
    nachbarIds: [
      TagName.Stopfen,
      TagName.Gleis,
      TagName.Weiche,
      TagName.Neulage,
      TagName.Meter,
      TagName.Stuek,
      TagName.EuroProMeter,
      TagName.EuroProStueck,
    ],
    optional: true,
  },
  [TagName.Meter]: {
    id: TagName.Meter,
    name: "Meter",
    typId: 4,
    nachbarIds: [
      TagName.Stopfen,
      TagName.Schleifen,
      TagName.Gleis,
      TagName.Neulage,
      TagName.Erhaltung,
      TagName.Befahrbar,
    ],
    optional: false,
  },
  [TagName.Stuek]: {
    id: TagName.Stuek,
    name: "Stück",
    typId: 4,
    nachbarIds: [
      TagName.Stopfen,
      TagName.Schleifen,
      TagName.Weiche,
      TagName.Neulage,
      TagName.Erhaltung,
      TagName.Befahrbar,
    ],
    optional: false,
  },
  [TagName.Euro]: {
    id: TagName.Euro,
    name: "Euro",
    typId: 4,
    nachbarIds: [],
    optional: false,
  },
  [TagName.EuroProMeter]: {
    id: TagName.EuroProMeter,
    name: "Euro pro Meter",
    typId: 4,
    nachbarIds: [
      TagName.Stopfen,
      TagName.Schleifen,
      TagName.Gleis,
      TagName.Neulage,
      TagName.Erhaltung,
      TagName.Befahrbar,
    ],
    optional: false,
  },
  [TagName.EuroProStueck]: {
    id: TagName.EuroProStueck,
    name: "Euro pro Stück",
    typId: 4,
    nachbarIds: [
      TagName.Stopfen,
      TagName.Schleifen,
      TagName.Weiche,
      TagName.Neulage,
      TagName.Erhaltung,
      TagName.Befahrbar,
    ],
    optional: false,
  },
  [TagName.Mindestverrechnung]: {
    id: TagName.Mindestverrechnung,
    name: "Mindestverrechnung",
    typId: 6,
    nachbarIds: [TagName.Stopfen, TagName.Stuek, TagName.EuroProStueck],
    optional: false,
  },
  [TagName.AORV]: {
    id: TagName.AORV,
    name: "AORV",
    typId: 1,
    nachbarIds: [],
    optional: false,
  },
  [TagName.Kran]: {
    id: TagName.Kran,
    name: "Kran",
    typId: 1,
    nachbarIds: [
      TagName.GroßeSchicht,
      TagName.KleineSchicht,
      TagName.Kranhelfer,
    ],
    optional: false,
  },
  [TagName.Kranhelfer]: {
    id: TagName.Kranhelfer,
    name: "Kranhelfer",
    typId: 2,
    nachbarIds: [TagName.Kran, TagName.GroßeSchicht, TagName.KleineSchicht],
    optional: false,
  },
  [TagName.GroßeSchicht]: {
    id: TagName.GroßeSchicht,
    name: "Große Schicht",
    typId: 3,
    nachbarIds: [TagName.Kran, TagName.Kranhelfer],
    optional: false,
  },
  [TagName.KleineSchicht]: {
    id: TagName.KleineSchicht,
    name: "Kleine Schicht",
    typId: 3,
    nachbarIds: [TagName.Kran, TagName.Kranhelfer],
    optional: false,
  },
};

const umsaetze: Umsatz[] = [ // eslint-disable-line
  {
    id: 1,
    datum: "2024-06-01",
    betrag: 29667.36,
    prognoseGruppeTyp: PrognoseGruppeTyp.ArgeOebbStopfen,
    prognoseStatus: UmsatzPrognoseStatus.AbgeschlossenAutomatisch,
    basisEinsatzIds: [72384],
    maschinenGruppe: MachineGroup.PLM_B1,
    quelle: UmsatzQuelle.Automatisch,
    berechnungsParameter: [
      {
        parameter: {
          id: ParameterId.Stopfen_Weiche_Neulage_Anzahl,
          name: "Anzahl",
          einheitId: UnitId.Stueck,
          tagIds: [
            TagName.Stopfen,
            TagName.Weiche,
            TagName.Neulage,
            TagName.Stuek,
          ],
          wertQuelle: BerechnungsParameterWertQuelle.Umsatz,
          gruppenNummer: 1,
          sortierungInGruppe: 1,
          inAuswahlbox: true,
        },
        wert: 3,
      },
      {
        parameter: {
          id: ParameterId.Stopfen_Weiche_Neulage_Euro_Stueck,
          name: "Weiche Neulage pro Stück",
          einheitId: UnitId.Euro_pro_Stueck,
          tagIds: [
            TagName.Stopfen,
            TagName.Weiche,
            TagName.Neulage,
            TagName.EuroProStueck,
          ],
          wertQuelle: BerechnungsParameterWertQuelle.KalkulatorischerAnsatz,
          gruppenNummer: 1,
          sortierungInGruppe: 2,
          inAuswahlbox: false,
        },
        wert: 9889.12,
      },
    ],
    berichtIds: [],
    referenzEinsatzIds: [],
    manuellAbgeschlossen: false,
    historie: {
      umsatzId: 1,
      gueltigVon: "2024-06-01",
      erstelltVon: "System",
    },
  },

  {
    id: 2,
    datum: "2024-06-01",
    betrag: 16524.13,
    prognoseGruppeTyp: PrognoseGruppeTyp.ArgeOebbStopfen,
    prognoseStatus: UmsatzPrognoseStatus.AbgeschlossenAutomatisch,
    basisEinsatzIds: [72159],
    maschinenGruppe: MachineGroup.MDZ_B4,
    quelle: UmsatzQuelle.Automatisch,
    berechnungsParameter: [
      {
        parameter: {
          id: ParameterId.Stopfen_Mindestverrechnung_Anzahl,
          name: "Einsätze",
          einheitId: UnitId.Stueck,
          tagIds: [TagName.Mindestverrechnung, TagName.Stopfen, TagName.Stuek],
          wertQuelle: BerechnungsParameterWertQuelle.Umsatz,
          gruppenNummer: 1,
          sortierungInGruppe: 1,
          inAuswahlbox: false,
        },
        wert: 1,
      },
      {
        parameter: {
          id: ParameterId.Stopfen_Mindestverrechnung_Euro_Stueck,
          name: "Mindestverrechnung",
          einheitId: UnitId.Euro_pro_Stueck,
          tagIds: [
            TagName.Mindestverrechnung,
            TagName.Stopfen,
            TagName.EuroProStueck,
          ],
          wertQuelle: BerechnungsParameterWertQuelle.KalkulatorischerAnsatz,
          gruppenNummer: 1,
          sortierungInGruppe: 2,
          inAuswahlbox: true,
        },
        wert: 16524.13,
      },
      {
        parameter: {
          id: ParameterId.Stopfen_Gleis_Neulage_Meter,
          name: "Laufmeter",
          einheitId: UnitId.Meter,
          tagIds: [],
          wertQuelle: BerechnungsParameterWertQuelle.Umsatz,
          gruppenNummer: 2,
          sortierungInGruppe: 1,
          inAuswahlbox: true,
        },
        wert: 400,
      },
      {
        parameter: {
          id: ParameterId.Stopfen_Gleis_Neulage_Meter_Euro,
          name: "Gleis Neulage",
          einheitId: UnitId.Euro_pro_Meter,
          tagIds: [],
          wertQuelle: BerechnungsParameterWertQuelle.KalkulatorischerAnsatz,
          gruppenNummer: 2,
          sortierungInGruppe: 2,
          inAuswahlbox: false,
        },
        wert: 32.42,
      },
      {
        parameter: {
          id: ParameterId.Stopfen_Weiche_Neulage_Anzahl,
          name: "Anzahl",
          einheitId: UnitId.Stueck,
          tagIds: [],
          wertQuelle: BerechnungsParameterWertQuelle.Umsatz,
          gruppenNummer: 3,
          sortierungInGruppe: 1,
          inAuswahlbox: true,
        },
        wert: 0,
      },
      {
        parameter: {
          id: ParameterId.Stopfen_Weiche_Neulage_Euro_Stueck,
          name: "Weiche Neulage",
          einheitId: UnitId.Euro_pro_Stueck,
          tagIds: [],
          wertQuelle: BerechnungsParameterWertQuelle.KalkulatorischerAnsatz,
          gruppenNummer: 3,
          sortierungInGruppe: 2,
          inAuswahlbox: false,
        },
        wert: 9889.12,
      },
    ],
    berichtIds: [],
    referenzEinsatzIds: [],
    manuellAbgeschlossen: false,
    historie: {
      umsatzId: 2,
      gueltigVon: "2024-06-01",
      erstelltVon: "System",
    },
  },
  {
    id: 3,
    datum: "2024-06-01",
    betrag: 30000,
    prognoseGruppeTyp: PrognoseGruppeTyp.ArgeOebbStopfen,
    prognoseStatus: UmsatzPrognoseStatus.AbgeschlossenAutomatisch,
    basisEinsatzIds: [],
    maschinenGruppe: MachineGroup.PLM_B1,
    quelle: UmsatzQuelle.ManuellPrognose,
    grund: "Laufmeter haben sich geändert.",
    berechnungsParameter: [
      {
        parameter: {
          id: ParameterId.Stopfen_Mindestverrechnung_Anzahl,
          name: "Anzahl Einsätze",
          einheitId: UnitId.Stueck,
          tagIds: [],
          wertQuelle: BerechnungsParameterWertQuelle.Umsatz,
          gruppenNummer: 1,
          sortierungInGruppe: 1,
          inAuswahlbox: false,
        },
        wert: 1,
      },
      {
        parameter: {
          id: ParameterId.Stopfen_Mindestverrechnung_Euro_Stueck,
          name: "Mindestverrechnung",
          einheitId: UnitId.Euro_pro_Stueck,
          tagIds: [],
          wertQuelle: BerechnungsParameterWertQuelle.KalkulatorischerAnsatz,
          gruppenNummer: 1,
          sortierungInGruppe: 2,
          inAuswahlbox: true,
        },
        wert: 30000,
      },
    ],
    berichtIds: [],
    referenzEinsatzIds: [],
    manuellAbgeschlossen: false,
    historie: {
      umsatzId: 3,
      gueltigVon: "2024-06-25T08:37:00",
      erstelltVon: "455f1f32-9fc9-4d29-9912-120a823a9630",
    },
  },
];

export function getFakeSystemApi(): SystemApiInterface {
  return {
    tag: undefined as any,
    /*tag: async (
      requestParameters: TagRequest,
      initOverrides?: RequestInit | InitOverrideFunction,
    ): Promise<ParameterTag> =>
      Object.values(tags).find((t) => t.id == requestParameters.id)!,*/
    tagRaw: undefined as any,

    tagSpeichern: undefined as any,
    tagSpeichernRaw: undefined as any,

    tagLoeschen: undefined as any,
    tagLoeschenRaw: undefined as any,

    tagKantenAktualisieren: undefined as any, // async (): Promise<void> => defer(() => {}),
    tagKantenAktualisierenRaw: undefined as any,

    berechnungsParameterSpeichern: undefined as any,
    berechnungsParameterSpeichernRaw: undefined as any,

    berechnungsParameterLoeschen: undefined as any,
    berechnungsParameterLoeschenRaw: undefined as any,

    berechnungsBaeume: undefined as any,
    berechnungsBaeumeRaw: undefined as any,

    berechnungsBaum: undefined as any,
    berechnungsBaumRaw: undefined as any,

    berechnungsBaumSpeichern: undefined as any,
    berechnungsBaumSpeichernRaw: undefined as any,

    berechnungsBaumLoeschen: undefined as any,
    berechnungsBaumLoeschenRaw: undefined as any,

    berechnungsBaumKnotenSpeichern: undefined as any,
    berechnungsBaumKnotenSpeichernRaw: undefined as any,

    importReportManagementReports: undefined as any,
    importReportManagementReportsRaw: undefined as any,
  };
}

export function getFakeAllgemeinApi(): AllgemeinApiInterface {
  return {
    einheiten: undefined as any,
    einheitenRaw: undefined as any,

    firmen: undefined as any,
    firmenRaw: undefined as any,

    maschinenGruppen: undefined as any,
    maschinenGruppenRaw: undefined as any,

    prognoseGruppen: undefined as any,
    /*prognoseGruppen: async (
      requestParameters: PrognoseGruppenRequest,
      initOverrides?: RequestInit | InitOverrideFunction,
    ): Promise<Array<PrognoseGruppe>> => [
      {
        id: 1,
        typ: PrognoseGruppeTyp.ArgeStopfenIi,
        name: "ARGE Stopfen II",
        von: "2020-01-01",
        bis: undefined,
      },
      {
        id: 2,
        typ: PrognoseGruppeTyp.ArgeEdkIi,
        name: "ARGE EDK II",
        von: "2020-01-01",
        bis: undefined,
      },
      {
        id: 3,
        typ: PrognoseGruppeTyp.Neuschienenschleifen,
        name: "Neuschienenschleifen",
        von: "2020-01-01",
        bis: undefined,
      },
      {
        id: 4,
        typ: PrognoseGruppeTyp.Aorv,
        name: "AORV",
        von: "2020-01-01",
        bis: undefined,
      },
    ],*/
    prognoseGruppenRaw: undefined as any,

    parameterTags: undefined as any,
    /*parameterTags: async (
      initOverrieds?: RequestInit | InitOverrideFunction,
    ): Promise<Array<ParameterTag>> => {
      return Object.values(tags);
    },*/
    parameterTagsRaw: undefined as any,

    berechnungsParameter: undefined as any,
    /* async (
      initOverrides?: RequestInit | InitOverrideFunction,
    ): Promise<Array<BerechnungsParameter>> => [
      {
        id: ParameterId.Stopfen_Gleis_Neulage_Meter,
        name: "Laufmeter",
        beschreibung: "Laufmeter Gleis Neulage Stopfen",
        einheitId: UnitId.Meter,
        gruppenNummer: 2,
        inAuswahlbox: false,
        sortierungInGruppe: 1,
        wertQuelle: BerechnungsParameterWertQuelle.Umsatz,
        tagIds: [
          TagName.Stopfen,
          TagName.Gleis,
          TagName.Neulage,
          TagName.Meter,
        ],
      },
      {
        id: ParameterId.Stopfen_Gleis_Neulage_Meter_Euro,
        name: "Gleis Neulage",
        beschreibung: "Euro pro Laufmeter Gleis Neulage",
        einheitId: UnitId.Euro_pro_Meter,
        gruppenNummer: 2,
        inAuswahlbox: true,
        sortierungInGruppe: 2,
        wertQuelle: BerechnungsParameterWertQuelle.KalkulatorischerAnsatz,
        tagIds: [
          TagName.Stopfen,
          TagName.Gleis,
          TagName.Neulage,
          TagName.EuroProMeter,
        ],
      },
      {
        id: ParameterId.Stopfen_Weiche_Neulage_Anzahl,
        name: "Anzahl",
        beschreibung: "Anzahl Weichen Neulage stopfen",
        einheitId: UnitId.Stueck,
        gruppenNummer: 1,
        inAuswahlbox: false,
        sortierungInGruppe: 1,
        wertQuelle: BerechnungsParameterWertQuelle.Umsatz,
        tagIds: [
          TagName.Stopfen,
          TagName.Weiche,
          TagName.Neulage,
          TagName.Meter,
        ],
      },
      {
        id: ParameterId.Stopfen_Weiche_Neulage_Euro_Stueck,
        name: "Weiche Neulage stopfen",
        beschreibung: "Euro pro Stück Weiche Neulage stopfen",
        einheitId: UnitId.Euro_pro_Stueck,
        gruppenNummer: 1,
        inAuswahlbox: true,
        sortierungInGruppe: 2,
        wertQuelle: BerechnungsParameterWertQuelle.KalkulatorischerAnsatz,
        tagIds: [
          TagName.Stopfen,
          TagName.Weiche,
          TagName.Neulage,
          TagName.EuroProMeter,
        ],
      },
      {
        id: ParameterId.Stopfen_Gleis_Neulage_Befahrbar_Meter,
        name: "Laufmeter",
        beschreibung: "Laufmeter Gleis Neulage befahrbar stopfen",
        einheitId: UnitId.Meter,
        gruppenNummer: 5,
        inAuswahlbox: false,
        sortierungInGruppe: 1,
        wertQuelle: BerechnungsParameterWertQuelle.Umsatz,
        tagIds: [
          TagName.Stopfen,
          TagName.Gleis,
          TagName.Neulage,
          TagName.Befahrbar,
          TagName.Meter,
        ],
      },
      {
        id: ParameterId.Stopfen_Gleis_Neulage_Befahrbar_Meter_Euro,
        name: "Gleis Neulage befahrbar",
        beschreibung: "Euro pro Laufmeter Gleis Neulage befahrbar stopfen",
        einheitId: UnitId.Euro_pro_Meter,
        gruppenNummer: 5,
        inAuswahlbox: true,
        sortierungInGruppe: 2,
        wertQuelle: BerechnungsParameterWertQuelle.KalkulatorischerAnsatz,
        tagIds: [
          TagName.Stopfen,
          TagName.Gleis,
          TagName.Neulage,
          TagName.Befahrbar,
          TagName.EuroProMeter,
        ],
      },
      {
        id: ParameterId.Stopfen_Mindestverrechnung_Anzahl,
        name: "Einsätze",
        beschreibung: "Anzahl der Einsätze",
        einheitId: UnitId.Stueck,
        gruppenNummer: 6,
        inAuswahlbox: false,
        sortierungInGruppe: 1,
        wertQuelle: BerechnungsParameterWertQuelle.Umsatz,
        tagIds: [
          TagName.Stopfen,
          TagName.Weiche,
          TagName.Neulage,
          TagName.Stuek,
        ],
      },
      {
        id: ParameterId.Stopfen_Mindestverrechnung_Anzahl,
        name: "Mindestverrechnung",
        beschreibung: "Mindestverrechnung pro Einsatz",
        einheitId: UnitId.Euro_pro_Stueck,
        gruppenNummer: 6,
        inAuswahlbox: false,
        sortierungInGruppe: 2,
        wertQuelle: BerechnungsParameterWertQuelle.KalkulatorischerAnsatz,
        tagIds: [
          TagName.Stopfen,
          TagName.Weiche,
          TagName.Neulage,
          TagName.EuroProStueck,
        ],
      },
    ],*/
    berechnungsParameterRaw: undefined as any,

    rechenmodell: undefined as any,
    /*rechenmodell: async (
      requestParameters: RechenmodellOperationRequest,
      initOverrides?: RequestInit | InitOverrideFunction,
    ): Promise<BerechnungsKnoten> => ({
      knotenTyp: BerechnungsKnotenTyp.OperatorKnoten,
      operator: BerechnungsOperatorId.Maximum,
      links: {
        knotenTyp: BerechnungsKnotenTyp.OperatorKnoten,
        operator: BerechnungsOperatorId.Multiplikation,
        links: {
          knotenTyp: BerechnungsKnotenTyp.ParameterKnoten,
          parameterId: ParameterId.Stopfen_Mindestverrechnung_Anzahl,
        },
        rechts: {
          knotenTyp: BerechnungsKnotenTyp.ParameterKnoten,
          parameterId: ParameterId.Stopfen_Mindestverrechnung_Euro_Stueck,
        },
      },
      rechts: {
        knotenTyp: BerechnungsKnotenTyp.OperatorKnoten,
        operator: BerechnungsOperatorId.Addition,
        links: {
          knotenTyp: BerechnungsKnotenTyp.OperatorKnoten,
          operator: BerechnungsOperatorId.Multiplikation,
          links: {
            knotenTyp: BerechnungsKnotenTyp.ParameterKnoten,
            parameterId: ParameterId.Stopfen_Gleis_Neulage_Meter,
          },
          rechts: {
            knotenTyp: BerechnungsKnotenTyp.ParameterKnoten,
            parameterId: ParameterId.Stopfen_Gleis_Neulage_Meter_Euro,
          },
        },

        rechts: {
          knotenTyp: BerechnungsKnotenTyp.OperatorKnoten,
          operator: BerechnungsOperatorId.Addition,
          links: {
            knotenTyp: BerechnungsKnotenTyp.OperatorKnoten,
            operator: BerechnungsOperatorId.Multiplikation,
            links: {
              knotenTyp: BerechnungsKnotenTyp.ParameterKnoten,
              parameterId: ParameterId.Stopfen_Gleis_Erhaltung_Meter,
            },
            rechts: {
              knotenTyp: BerechnungsKnotenTyp.ParameterKnoten,
              parameterId: ParameterId.Stopfen_Gleis_Erhaltung_Euro_Meter,
            },
          },
          rechts: {
            knotenTyp: BerechnungsKnotenTyp.OperatorKnoten,
            operator: BerechnungsOperatorId.Addition,
            links: {
              knotenTyp: BerechnungsKnotenTyp.OperatorKnoten,
              operator: BerechnungsOperatorId.Multiplikation,
              links: {
                knotenTyp: BerechnungsKnotenTyp.ParameterKnoten,
                parameterId: ParameterId.Stopfen_Weiche_Neulage_Anzahl,
              },
              rechts: {
                knotenTyp: BerechnungsKnotenTyp.ParameterKnoten,
                parameterId: ParameterId.Stopfen_Weiche_Neulage_Euro_Stueck,
              },
            },
            rechts: {
              knotenTyp: BerechnungsKnotenTyp.OperatorKnoten,
              operator: BerechnungsOperatorId.Addition,
              links: {
                knotenTyp: BerechnungsKnotenTyp.OperatorKnoten,
                operator: BerechnungsOperatorId.Multiplikation,
                links: {
                  knotenTyp: BerechnungsKnotenTyp.ParameterKnoten,
                  parameterId:
                    ParameterId.Stopfen_Gleis_Neulage_Befahrbar_Meter,
                },
                rechts: {
                  knotenTyp: BerechnungsKnotenTyp.ParameterKnoten,
                  parameterId:
                    ParameterId.Stopfen_Gleis_Neulage_Befahrbar_Meter_Euro,
                },
              },
              rechts: {
                knotenTyp: BerechnungsKnotenTyp.OperatorKnoten,
                operator: BerechnungsOperatorId.Multiplikation,
                links: {
                  knotenTyp: BerechnungsKnotenTyp.ParameterKnoten,
                  parameterId: ParameterId.Stopfen_Weiche_Erhaltung_Anzahl,
                },
                rechts: {
                  knotenTyp: BerechnungsKnotenTyp.ParameterKnoten,
                  parameterId: ParameterId.Stopfen_Weiche_Erhaltung_Euro_Stueck,
                },
              },
            },
          },
        },
      },
    }),*/
    rechenmodellRaw: undefined as any,
  };
}

//const tasks: RailiumEinsatz[] = JSON.parse(tasksRaw);

export function getFakeRailiumApi(): RailiumApiInterface {
  return {
    railiumEinsaetze: undefined as any,
    /*railiumEinsaetze: async (
      requestParameters: RailiumEinsaetzeRequest,
      initOverrides?: RequestInit | InitOverrideFunction,
    ): Promise<Array<RailiumEinsatz>> => {
      const filterFunction = rsqlFilter(requestParameters.filter);
      console.warn("filter: " + requestParameters.filter);
      return defer(() => {
        requestParameters.filter;
        return tasks.filter(filterFunction);
      });
    },*/
    railiumEinsaetzeRaw: undefined as any,

    railiumEinsaetzeAusschnitt: undefined as any,
    /*railiumEinsaetzeAusschnitt: async (
      requestParameters: RailiumEinsaetzeAusschnittRequest,
      initOverrides?: RequestInit | InitOverrideFunction,
    ): Promise<RailiumEinsaetzeAusschnitt> => {
      const filterFunction = rsqlFilter(requestParameters.filter);
      const t = tasks.filter(filterFunction);
      const idx = requestParameters.seite;
      return defer(() => {
        return {
          anzahlEinsaetze: Math.min(t.length, 10),
          anzahlEinsaetzeGesamt: t.length,
          einsaetze: t.slice((idx - 1) * 10, idx * 10),
        };
      });
    },*/
    railiumEinsaetzeAusschnittRaw: undefined as any,

    importEinsaetze: undefined as any,
    importEinsaetzeRaw: undefined as any,
  };
}

export function getFakePrognoseApi(): PrognoseApiInterface {
  return {
    umsaetzeAusschnitt: undefined as any,
    /*umsaetzeAusschnitt: async (
      requestParameters: UmsaetzeAusschnittRequest,
      initOverrides?: RequestInit | InitOverrideFunction,
    ): Promise<UmsaetzeAusschnitt> => {
      //const filterFunction = rsqlFilter(requestParameters.filter);
      //const s = umsaetze.filter(filterFunction);
      const idx = requestParameters.seite;
      return defer(() => {
        return {
          anzahlUmsaetze: Math.min(umsaetze.length, 10),
          anzahlUmsaetzeGesamt: umsaetze.length,
          umsaetze: umsaetze.slice((idx - 1) * 10, idx * 10),
        };
      });
    },*/
    umsaetzeAusschnittRaw: undefined as any,

    einsatzUmsatz: undefined as any,
    /*einsatzUmsatz: async (
      requestParameters: EinsatzUmsatzRequest,
      initOverrides?: RequestInit | InitOverrideFunction,
    ): Promise<Umsatz> => {
      return defer(() => {
        if (requestParameters.railiumId == 72384) {
          return umsaetze[0];
        } else if (requestParameters.railiumId == 72159) {
          return umsaetze[1];
        } else {
          return umsaetze[2];
        }
      });
    },*/
    einsatzUmsatzRaw: undefined as any,

    kalkulatorischeAnsaetze: undefined as any,
    /*kalkulatorischeAnsaetze: async (
      requestParameters: KalkulatorischeAnsaetzeRequest,
      initOverrides?: RequestInit | InitOverrideFunction,
    ): Promise<Array<KalkulatorischerAnsatz>> => {
      const filterFunction = rsqlFilter(requestParameters.filter);
      console.warn("filter: " + requestParameters.filter);
      return defer(() => {
        //requestParameters.filter;
        return [
          {
            id: 1,
            von: "2024-01-01",
            bis: "2024-12-31",
            prognoseGruppenTyp: PrognoseGruppeTyp.ArgeStopfenIi,
            maschinenGruppe: MachineGroup.PLM_B1,
            berechnungsParameter: {
              parameter: {
                id: ParameterId.Stopfen_Mindestverrechnung_Euro_Stueck,
                name: "Mindestverrechnung",
                einheitId: UnitId.Stueck,
                tagIds: [],
                wertQuelle:
                  BerechnungsParameterWertQuelle.KalkulatorischerAnsatz,
                beschreibung:
                  "Mindestumsatz (falls Umsatzsumme von Einsätzen innerhalb von 12h geringer).",
                inAuswahlbox: true,
                gruppenNummer: 1,
                sortierungInGruppe: 1,
              },
              wert: 17000,
            },
          },
          {
            id: 2,
            von: "2024-01-01",
            bis: "2024-12-31",
            prognoseGruppenTyp: PrognoseGruppeTyp.ArgeStopfenIi,
            maschinenGruppe: MachineGroup.PLM_B1,
            berechnungsParameter: {
              parameter: {
                id: ParameterId.Stopfen_Weiche_Neulage_Euro_Stueck,
                name: "Weiche Neulage",
                einheitId: UnitId.Euro_pro_Stueck,
                tagIds: [],
                wertQuelle:
                  BerechnungsParameterWertQuelle.KalkulatorischerAnsatz,
                inAuswahlbox: true,
                gruppenNummer: 1,
                sortierungInGruppe: 1,
              },
              wert: 9889.12,
            },
          },
          {
            id: 3,
            von: "2024-01-01",
            bis: "2024-12-31",
            prognoseGruppenTyp: PrognoseGruppeTyp.ArgeStopfenIi,
            maschinenGruppe: MachineGroup.PLM_B1,
            berechnungsParameter: {
              parameter: {
                id: ParameterId.Stopfen_Gleis_Neulage_Meter_Euro,
                name: "Gleis Neulage",
                einheitId: UnitId.Euro_pro_Meter,
                tagIds: [],
                wertQuelle:
                  BerechnungsParameterWertQuelle.KalkulatorischerAnsatz,
                inAuswahlbox: true,
                gruppenNummer: 1,
                sortierungInGruppe: 1,
              },
              wert: 32.42,
            },
          },
          {
            id: 4,
            von: "2024-01-01",
            bis: "2024-12-31",
            prognoseGruppenTyp: PrognoseGruppeTyp.ArgeStopfenIi,
            maschinenGruppe: MachineGroup.PLM_B1,
            berechnungsParameter: {
              parameter: {
                id: ParameterId.Stopfen_Weiche_Erhaltung_Euro_Stueck,
                name: "Weiche Erhaltung",
                einheitId: UnitId.Euro_pro_Stueck,
                tagIds: [],
                wertQuelle:
                  BerechnungsParameterWertQuelle.KalkulatorischerAnsatz,
                inAuswahlbox: true,
                gruppenNummer: 1,
                sortierungInGruppe: 1,
              },
              wert: 3157.4,
            },
          },
          {
            id: 5,
            von: "2024-01-01",
            bis: "2024-12-31",
            prognoseGruppenTyp: PrognoseGruppeTyp.ArgeStopfenIi,
            maschinenGruppe: MachineGroup.PLM_B1,
            berechnungsParameter: {
              parameter: {
                id: ParameterId.Stopfen_Gleis_Erhaltung_Euro_Meter,
                name: "Gleis Erhaltung",
                einheitId: UnitId.Euro_pro_Meter,
                tagIds: [],
                wertQuelle:
                  BerechnungsParameterWertQuelle.KalkulatorischerAnsatz,
                inAuswahlbox: true,
                gruppenNummer: 1,
                sortierungInGruppe: 1,
              },
              wert: 9.42,
            },
          },
          {
            id: 6,
            von: "2023-01-01",
            bis: "2023-12-31",
            prognoseGruppenTyp: PrognoseGruppeTyp.ArgeStopfenIi,
            maschinenGruppe: MachineGroup.PLM_B1,
            berechnungsParameter: {
              parameter: {
                id: ParameterId.Stopfen_Gleis_Neulage_Meter_Euro,
                name: "Gleis Neulage",
                einheitId: UnitId.Euro_pro_Meter,
                tagIds: [],
                wertQuelle:
                  BerechnungsParameterWertQuelle.KalkulatorischerAnsatz,
                inAuswahlbox: true,
                gruppenNummer: 1,
                sortierungInGruppe: 1,
              },
              wert: 31.34,
            },
          },
        ].filter(filterFunction);
      });
    },*/
    kalkulatorischeAnsaetzeRaw: undefined as any,

    kalkulatorischenAnsatzSpeichern: undefined as any,
    kalkulatorischenAnsatzSpeichernRaw: undefined as any,

    kalkulatorischeAnsaetzeSpeichern: undefined as any,
    kalkulatorischeAnsaetzeSpeichernRaw: undefined as any,
    
    umsatz: undefined as any,
    /*umsatz: async (
      requestParameters: UmsatzRequest,
      initOverrides?: RequestInit | InitOverrideFunction,
    ): Promise<Umsatz> => {
      return defer(() => {
        if (requestParameters.id == 1) {
          return umsaetze[0];
        } else if (requestParameters.id == 2) {
          return umsaetze[1];
        } else {
          return umsaetze[2];
        }
      });
    },*/
    umsatzRaw: undefined as any,

    umsatzSpeichern: undefined as any,
    /*umsatzSpeichern: async (
      requestParameters: UmsatzSpeichernRequest,
      initOverrides?: RequestInit | InitOverrideFunction,
    ): Promise<Umsatz> => {
      return requestParameters.umsatz;
    },*/
    umsatzSpeichernRaw: undefined as any,

    umsatzLoeschen: undefined as any,
    /*umsatzLoeschen: async (
      requestParameters: UmsatzLoeschenRequest,
      initOverrides?: RequestInit | InitOverrideFunction,
    ): Promise<void> => {
      return defer(() => {});
    },*/
    umsatzLoeschenRaw: undefined as any,

    umsatzHistorie: undefined as any,
    umsatzHistorieRaw: undefined as any,

    signalWoerter: undefined as any,
    signalWoerterRaw: undefined as any,

    signalWort: undefined as any,
    signalWortRaw: undefined as any,

    signalWortSpeichern: undefined as any,
    signalWortSpeichernRaw: undefined as any,
  };
}

export function getFakeStrategieApi(): StrategieApiInterface {
  return {
    strategischenUmsatzLaden: undefined as any,
    /*strategischenUmsatzLaden: async (
      requestParameters: StrategischenUmsatzLadenRequest,
      initOverrides?: RequestInit | InitOverrideFunction,
    ): Promise<StrategischerUmsatz[]> => {
      return defer(() => {
        if (requestParameters.prognoseGruppeId == 1) {
          return [
            {
              firmaName: "BBW",
              maschinenGruppe: "GW-MDZ B1",
              werte: [10, 20, 30, 70, 90, 110, 120, 140, 90, 70, 60, 20],
            },
            {
              firmaName: "BBW",
              maschinenGruppe: "PLM B1",
              werte: [40, 50, 70, 70, 90, 110, 120, 140, 90, 70, 60, 20],
            },
            {
              firmaName: "BBW",
              maschinenGruppe: "GW-MDZ B4",
              werte: [5, 10, 60, 70, 90, 110, 120, 140, 90, 70, 60, 20],
            },
            {
              firmaName: "BBW",
              maschinenGruppe: "GW-MDZ B4_1",
              werte: [5, 10, 60, 70, 90, 110, 120, 140, 90, 70, 60, 20],
            },
            {
              firmaName: "BBW",
              maschinenGruppe: "GW-MDZ B4_2",
              werte: [5, 10, 60, 70, 90, 110, 120, 140, 90, 70, 60, 20],
            },
            {
              firmaName: "BBW",
              maschinenGruppe: "GW-MDZ B4_3",
              werte: [5, 10, 60, 70, 90, 110, 120, 140, 90, 70, 60, 20],
            },
            {
              firmaName: "BBW",
              maschinenGruppe: "GW-MDZ B4_4",
              werte: [5, 10, 60, 70, 90, 110, 120, 140, 90, 70, 60, 20],
            },
            {
              firmaName: "BBW",
              maschinenGruppe: "GW-MDZ B4_5",
              werte: [5, 10, 60, 70, 90, 110, 120, 140, 90, 70, 60, 20],
            },
            {
              firmaName: "Strabag",
              maschinenGruppe: "MDZ SA1",
              werte: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            },
          ];
        } else {
          return [];
        }
      });
    },*/
    strategischenUmsatzLadenRaw: undefined as any,

    umsatzMaschinengruppeSpeichern: undefined as any,
    umsatzMaschinengruppeSpeichernRaw: undefined as any,
  };
}
